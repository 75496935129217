import React from "react";
import "./karriereMachen.scss";

export default function KarriereMachen() {
  return (
    <>
      <div className="karriere-machen-content-alignment">
        <div className="section-title" id="Karriere">
          <h2>Karriere. Machen.</h2>
          <p>
            Willst du beruflich langfristig zufrieden sein, brauchst du einen
            Job, der perfekt zu dir passt. Das klingt simpel. Doch so einfach
            ist es nicht. Passt die Jobbeschreibung, ist das Gehalt vielleicht
            zu niedrig. Stimmt das Gehalt, vertritt das Unternehmen nicht
            zwingend die persönlichen Werte.
          </p>
          <p>
            Damit wirklich alles stimmt, muss man viel Zeit in die Jobsuche
            investieren. Doch wir helfen dir. Mit unserer Unterstützung kommst
            du schneller an deinen Traumjob Wir legen den Fokus auf Studenten,
            Absolventen und Young Professionals. Solltest du also gerade
            anfangen, den Arbeitsmarkt zu erobern, sind wir mit unserer
            Expertise für dich da. Und das während der Jobsuche und dem gesamten
            Prozess der Bewerbung.
          </p>
          <div
            className="white-button"
            style={{ padding: "0", justifyContent: "flex-start" }}
          >
            <button>Kostenfreien Karrierecheck starten</button>
          </div>
        </div>
      </div>
    </>
  );
}
