import React, { useEffect, useState, useCallback } from "react";
import { toast, ToastContainer } from "react-toastify";
import { ApiGet, ApiPost, ApiPut } from "../../../../helpers/API/ApiData";
import "./dokumenteSection.scss";
import { useHistory } from "react-router-dom";
export default function DokumenteSection(props) {
  const { inputValue, errors, setErrors, setInputValue } = props;
  const [errorsForPhoto, setErrorsForPhoto] = useState({});
  const [isAgree, setIsAgree] = useState(false);

  const regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  let history = useHistory();

  const validateforUserData = () => {
    let isFormValid = true;
    let errors = {};

    if (!inputValue?.fname?.trim() || inputValue?.fname === "") {
      isFormValid = false;
      errors["fname"] = "*Bitte überprüfen Sie ihre Eingabe.";
    }
    if (!inputValue?.lname?.trim() || inputValue?.lname === "") {
      isFormValid = false;
      errors["lname"] = "*Bitte überprüfen Sie ihre Eingabe.";
    }

    if (
      !inputValue?.email?.trim() ||
      inputValue?.email === "" ||
      regexEmail?.test(inputValue?.email) === false
    ) {
      isFormValid = false;
      errors["email"] = "*Bitte überprüfen Sie ihre Eingabe.";
    }
    if (!inputValue?.contactNo || inputValue?.contactNo === "") {
      isFormValid = false;
      errors["contactNo"] = "*Bitte überprüfen Sie ihre Eingabe.";
    }
    // if (!inputValue?.city?.trim() || inputValue?.city === "") {
    //   isFormValid = false;
    //   errors["city"] = "*Bitte überprüfen Sie ihre Eingabe.";
    // }

    // if (!inputValue?.DoB || inputValue?.DoB === "") {
    //   isFormValid = false;
    //   errors["DoB"] = "*Bitte überprüfen Sie ihre Eingabe.";
    // }
    // if (!inputValue?.menu?.trim() || inputValue?.time === "") {
    //   isFormValid = false;
    //   errors["menu"] = "*Bitte überprüfen Sie ihre Eingabe.";
    // }
    if (!inputValue?.genger || inputValue?.genger === "") {
      isFormValid = false;
      errors["genger"] = "*Bitte überprüfen Sie ihre Eingabe.";
    }
    // if (inputValue && !inputValue?.menu) {
    //   isFormValid = false;
    //   errors["menu"] = "*Bitte überprüfen Sie ihre Eingabe.";
    // }
    // if (inputValue && !inputValue?.resume) {
    //   isFormValid = false;
    //   errors["resume"] = "*Bitte überprüfen Sie ihre Eingabe.";
    // }
    // if (inputValue && !inputValue?.writeTo) {
    //   isFormValid = false;
    //   errors["writeTo"] = "*Bitte überprüfen Sie ihre Eingabe.";
    // }
    // if (inputValue && !inputValue?.certificate) {
    //   isFormValid = false;
    //   errors["certificate"] = "*Bitte überprüfen Sie ihre Eingabe.";
    // }
    // if (inputValue && !inputValue?.other) {
    //   isFormValid = false;
    //   errors["other"] = "*Bitte überprüfen Sie ihre Eingabe.";
    // }
    if (isAgree === false) {
      isFormValid = false;
      errors["check"] = "*Bitte überprüfen Sie ihre Eingabe.";
    }

    setErrors(errors);
    return isFormValid;
  };

  const handleOnChnageAddImg = (e) => {
    const { name } = e.target;
    setInputValue({ ...inputValue, [name]: e.target.files[0] });
    setErrorsForPhoto({ ...errorsForPhoto, [name]: "" });
  };

  const subnitForm = () => {
    if (validateforUserData()) {
      let formData = new FormData();
      formData.append("fname", inputValue?.fname);
      formData.append("lname", inputValue?.lname);
      formData.append("email", inputValue?.email);
      formData.append("contactNo", inputValue?.contactNo);
      // formData.append("city", inputValue?.city);
      // formData.append("DoB", inputValue?.DoB);
      formData.append("genger", inputValue?.genger);
      // formData.append("menu", inputValue?.menu);
      formData.append("resume", inputValue?.resume);
      formData.append("writeTo", inputValue?.writeTo);
      formData.append("certificate", inputValue?.certificate);
      formData.append("other", inputValue?.other);

      ApiPost(`applyJob/create`, formData)
        .then((res) => {
          toast.success(
            "Vielen Dank für Deine Bewerbung,wir melden uns binnen 48h bei dir."
          );
          setInputValue({});
          history.push("/karriere");
        })
        .catch((error) => {
          toast.error("Etwas ist schief gelaufen. Bitte versuche es erneut");
        });
    }
  };
  return (
    <div>
      <ToastContainer />
      <div className="dokument-section-all-content-alignment">
        <div className="section-page">
          <h4>DOKUMENTE</h4>
          <p>
            Bitte lade hier die für die Stelle benötigten Bewerbungsunterlagen
            hoch (z.B. Lebenslauf, Anschreiben, Zeugnisse, Gehaltsvorstellung
            etc.).
          </p>
        </div>
        <div className="all-box-center-alignment">
          <div className="box-design">
            <div className="grid">
              <div className="grid-items">
                <span>Lebenslauf *</span>
                <div className="green-box">
                  <input
                    type="file"
                    name="resume"
                    id="resume"
                    onChange={(e) => handleOnChnageAddImg(e)}
                  />
                </div>
                <span style={{ color: "#d92c2c" }}>{errors?.resume}</span>
              </div>
              <div className="grid-items">
                <span>Anschreiben </span>
                <div className="green-box">
                  <input
                    type="file"
                    name="writeTo"
                    id="writeTo"
                    onChange={(e) => handleOnChnageAddImg(e)}
                  />
                </div>
                {/* <span style={{ color: "#d92c2c" }}>{errors?.writeTo}</span> */}
              </div>
              <div className="grid-items">
                <span>Arbeitszeugnis </span>
                <div className="green-box">
                  <input
                    type="file"
                    name="certificate"
                    id="certificate"
                    onChange={(e) => handleOnChnageAddImg(e)}
                  />
                </div>
                {/* <span style={{ color: "#d92c2c" }}>{errors?.certificate}</span> */}
              </div>
              <div className="grid-items">
                <span>Andere </span>
                <div className="green-box">
                  <input
                    type="file"
                    name="other"
                    id="other"
                    onChange={(e) => handleOnChnageAddImg(e)}
                  />
                </div>
                {/* <span style={{ color: "#d92c2c" }}>{errors?.other}</span> */}
              </div>
            </div>
            <div className="checkbox-text-alignment">
              {/* <div>
                <input type="checkbox" id="check" />
                <label htmlFor="check"></label>
              </div> */}
              <div>
                <input
                  type="checkbox"
                  checked={isAgree}
                  name="check"
                  id="new-check"
                  onChange={(e) => setIsAgree(e.target.checked)}
                />
                <label for="new-check"></label>
              </div>
              <div>
                <span>
                  Hiermit bestätige ich, dass ich die Datenschutzerklärung zur
                  Kenntnis genommen habe.*
                </span>
                {isAgree === false && (
                  <span style={{ color: "#d92c2c" }}>{errors?.check}</span>
                )}
              </div>
            </div>
            <div className="all-button-center-alignment">
              <button
                onClick={() => {
                  subnitForm();
                }}>
                Bewerbung abschicken
              </button>
              <button>Abbruch</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
