import React from "react";
import "./steper.scss";
export default function FinancialGuidanceSteper() {
  return (
    <>
      <div className="all-steper-top-rigth-content-alignment">
        <div className="all-steper-grid-alignment">
          <div className="steper-grid">
            <div className="steper-grid-items">
              <a href="#Unternehmen-sberatung">
                <span className="steper-all-content-new-alignment">
                  Unser Vorgehen
                </span>
              </a>
            </div>
            <div className="steper-grid-items">
              <div className="steper-round-design">
                <div></div>
              </div>
            </div>
          </div>
          <div className="steper-grid">
            <div className="steper-grid-items">
              <a href="#Beratung-nach-Norm">
                <span className="steper-all-content-new-alignment">
                  Beratung nach Norm
                </span>
              </a>
            </div>
            <div className="steper-grid-items">
              <div className="steper-round-design">
                <div></div>
              </div>
            </div>
          </div>

          <div className="steper-grid">
            <div className="steper-grid-items">
              <a href="#kontakt-aufnehmen">
              <span className="steper-all-content-new-alignment">
                Kontakt aufnehmen
              </span>
              </a>
            </div>
            <div className="steper-grid-items">
              <div className="steper-round-design">
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
