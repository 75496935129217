import React, { useEffect, useState, useCallback } from "react";
import "./dasAngebot.scss";
import { NavLink, useHistory } from "react-router-dom";

export default function DasAngebot() {
  const history = useHistory();
  return (
    <>
      <div className="dasange-bot-all-content-alignment" id="Das-Angebot">
        <div className="main-title">
          <h1>
            Jetzt bei <span>850 Unternehmen</span> bewerben und Martkwert
            checken
          </h1>
        </div>
        <div className="das-angebot-title">
          <h2>Das Angebot</h2>
          <p>
            Wir optimieren Ihren Berufseinstieg und vernetzen dabei persönliche
            und digitale Unterstützung. Unser Beratungsangebot umfasst folgende
            Punkte:
          </p>
        </div>
        {/* <div className="all-text-alignment">
          <div className="text-style">
            <h5>
              <span
                style={{
                  paddingRight: "10px",
                  position: "relative",
                  top: "-6px",
                }}
              >
                .
              </span>{" "}
              Jobvermittlung
            </h5>
            <p>
              Mit Seminaren, Coachings und persönlichen Gesprächen bereiten
              unsere Experten die jungen Fachkräfte optimal auf bevorstehende
              Bewerbungssituationen vor. Dabei werden nicht nur klassische
              Situationen wie das Vorstellungsgespräch trainiert, sondern auch
              Assessment Center und Gehaltsgespräche. Unsere Coaches sind
              Experten im Bereich Recruiting und können daher besonders
              individuell und bedarfsgerecht beraten - so ist eine allumfassende
              Professionalität und Qualifizierung für unsere Kunden garantiert.
            </p>
            <div className="button-new-mobile-center-alignment">
              <button
                onClick={() => {
                  history.push("/steper-view");
                }}
              >
                Jetzt informieren und Potential entfalten
              </button>
            </div>
          </div>
          <div className="text-style">
            <h5>
              {" "}
              <span
                style={{
                  paddingRight: "10px",
                  position: "relative",
                  top: "-6px",
                }}
              >
                .
              </span>
              Jobportal
            </h5>
            <p>
              In unserem kostenfreien Jobportal finden Studenten, Absolventen
              und Fachkräfte nach offenen Stellen, Praktika und anderen
              Karrieremöglichkeiten bei über 850 Arbeitgebern suchen. So finden
              auch Sie zeiteffizient den Job, der zu Ihnen und Ihren Fähigkeiten
              passt.
            </p>
            <div className="button-new-mobile-center-alignment">
              <button
                onClick={() => {
                  history.push("/steper-view");
                }}
              >
                Jetzt informieren und Potential entfalten
              </button>
            </div>
          </div>
          <div className="text-style">
            <h5>
              {" "}
              <span
                style={{
                  paddingRight: "10px",
                  position: "relative",
                  top: "-6px",
                }}
              >
                .
              </span>
              Karrierecoaching
            </h5>
            <p>
              Im Karrierecoaching kann unter anderem das dreistündige
              Karriereseminar besucht werden. Von Anschreiben und Lebenslauf bis
              hin zum Vorstellungsgespräch - hier wird der gesamte
              Bewerbungsprozess trainiert. Im daran anknüpfenden
              Gehaltsverhandlungsseminar folgt die Vorbereitung auf einen der
              wohl wichtigsten Punkte im
            </p>
            <div className="button-new-mobile-center-alignment">
              <button
                onClick={() => {
                  history.push("/steper-view");
                }}
              >
                Jetzt informieren und Potential entfalten
              </button>
            </div>
          </div>
          <div className="text-style">
            <h5>
              {" "}
              <span
                style={{
                  paddingRight: "10px",
                  position: "relative",
                  top: "-6px",
                }}
              >
                .
              </span>
              Jobfindungsprozess
            </h5>
            <p>
              Die Frage nach dem gewünschten Gehalt. Mithilfe unseres Seminars
              haben unsere Teilnehmer die Chance auf bis zu 15% mehr Gehalt im
              neuen Job. Zu guter Letzt werden unsere Kunden zu ihrer Social
              Media Präsenz beraten, sowohl auf LinkedIn und XING als auch auf
              Facebook und Instagram.
            </p>
            <div className="button-new-mobile-center-alignment">
              <button
                onClick={() => {
                  history.push("/steper-view");
                }}
              >
                Jetzt informieren und Potential entfalten
              </button>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}
