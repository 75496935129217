import React, { useEffect, useState } from "react";
import "./kontakt.scss";
import KontaktStper from "../../../components/steper/kontaktStper";
import KontaktForm from "./kontaktForm";
import FullImage from "../../../assets/images/full-map.png";
// import { Map } from "google-maps-react";
import KontaktInformation from "./kontaktInformation";
import Map from "./Map";
import { NavLink } from "react-router-dom";
import MobileViewHeader from "../../MobileViewHeader";

export default function Kontakt() {
  const [clickedLatLng, setClickedLatLng] = useState("");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <MobileViewHeader />
      <>
        <KontaktStper />
      </>
      <div className="kontakt-page-content-alignment">
        <div className="white-button">
          <NavLink to="/steper-view">
            <button>JETZT BERATEN LASSEN</button>
          </NavLink>
        </div>
        <div className="container-lg">
          <div className="hero-text-style" id="Kontaktformular">
            <h1>Kontakt</h1>
            <p>
              Vielen Dank für dein Interesse an unseren Beratungsleistungen. Wir
              freuen uns darauf, dich auf dem Weg in eine finanziell sichere
              Zukunft begleiten zu dürfen. Kann es losgehen? Dann fülle das
              untenstehende Formular aus. Wir melden uns innerhalb von 24
              Stunden zurück.
            </p>
          </div>
          <KontaktForm />
        </div>
        <div className="full-page-image" id="#Karte">
          <Map
            center={{ lat: 53.0847155, lng: 8.779814 }}
            zoom={17}
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBwyQ2fI1UgcPSJJ8HuYLhVQRFHYpB0iRE"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `500px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>

        <div className="container-lg">
          <KontaktInformation />
        </div>
      </div>
    </div>
  );
}
