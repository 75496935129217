import React, { useEffect, useState, useCallback } from "react";
import "./apply.scss";
import LeftIcon from "../../../assets/icons/left-whtie.svg";
import DokumenteSection from "./dokumenteSection";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { NavLink } from "react-router-dom";
import { ApiGet, ApiPost, ApiPut } from "../../../helpers/API/ApiData";
import MobileViewHeader from "../../MobileViewHeader";
import { useHistory, useLocation } from "react-router-dom";

export default function Apply() {
  let history = useHistory();
  const { state } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const [inputValue, setInputValue] = useState({});
  const [errors, setErrors] = useState({});
  const [errorsForPhoto, setErrorsForPhoto] = useState({});

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  // const handleOnChnageAddImg = (e) => {
  //   const { name } = e.target;
  //   setInputValue({ ...inputValue, [name]: e.target.files[0] });
  //   setErrorsForPhoto({ ...errorsForPhoto, [name]: "" });
  // };
  const redirectBackPage = (e) => {
    history.push(`/karriere-information/${state}`);
  };

  return (
    <div>
      <MobileViewHeader />
      <div className="apply-all-content-alignment">
        <div className="white-button">
          <NavLink to="/kontakt">
            <button>KONTAKT AUFNEHMEN</button>
          </NavLink>
        </div>
        <div className="container-xl">
          <div className="back-button-content-alignment">
            <img
              src={LeftIcon}
              alt="LeftIcon"
              onClick={(e) => redirectBackPage(e)}
            />
            <span>Stellen durchsuchen</span>
          </div>
          <div className="information-new-list-content-left-right-content-alignment">
            <div className="financial-content-alignment">
              <h2>Financial Guide Latest</h2>
              <span>Festanstellung, Vollzeit · Bremen</span>
            </div>
            <div className="deine-bewerbung-content-alignment">
              <h5>DEINE BEWERBUNG</h5>
              <p>
                Wir freuen uns über Dein Interesse an der FE Finance als
                Arbeitgeber. Bitte fülle das folgende kurze Formular aus.
                Solltest Du Schwierigkeiten mit dem Upload Deiner Daten haben,
                wende dich gerne per Email an kommunikation@fe-finance.de.
              </p>
            </div>
            <div className="form-center-alignment">
              <div className="form-box">
                <div className="apply-input-grid">
                  <div className="apply-input-grid-items">
                    <input
                      type="text"
                      placeholder="Vorname"
                      id="fname"
                      name="fname"
                      value={inputValue?.fname}
                      onChange={(e) => {
                        handleOnChange(e);
                      }}
                    />
                    <span style={{ color: "#d92c2c" }}>{errors?.fname}</span>
                  </div>
                  <div className="apply-input-grid-items">
                    <input
                      type="text"
                      placeholder="Nachname"
                      id="lname"
                      name="lname"
                      value={inputValue?.lname}
                      onChange={(e) => {
                        handleOnChange(e);
                      }}
                    />
                    <span style={{ color: "#d92c2c" }}>{errors?.lname}</span>
                  </div>
                </div>
                <div className="email-top-bottom-alignment">
                  <input
                    type="text"
                    placeholder="E-Mail"
                    id="email"
                    name="email"
                    value={inputValue?.email}
                    onChange={(e) => {
                      handleOnChange(e);
                    }}
                  />
                  <span style={{ color: "#d92c2c" }}>{errors?.email}</span>
                </div>
                <div className="email-top-bottom-alignment">
                  {/* <div className="apply-input-grid-items">
                    <input
                      type="text"
                      placeholder="ORT"
                      id="city"
                      name="city"
                      value={inputValue?.city}
                      onChange={(e) => {
                        handleOnChange(e);
                      }}
                    />
                    <span style={{ color: "#d92c2c" }}>{errors?.city}</span>
                  </div> */}
                  <div className="apply-input-grid-items">
                    <input
                      type="text"
                      placeholder="Telefon"
                      id="contactNo"
                      name="contactNo"
                      value={inputValue?.contactNo}
                      onChange={(e) => {
                        handleOnChange(e);
                      }}
                    />
                    <span style={{ color: "#d92c2c" }}>
                      {errors?.contactNo}
                    </span>
                  </div>
                  {/* <div className="apply-input-grid-items">
                    <input
                      type="date"
                      placeholder="DD.MM.YY"
                      id="DoB"
                      name="DoB"
                      value={inputValue?.DoB}
                      onChange={(e) => {
                        handleOnChange(e);
                      }}
                    />
                    <span style={{ color: "#d92c2c" }}>{errors?.DOB}</span>
                  </div>
                  <div className="apply-input-grid-items">
                    <select
                      name="menu"
                      id="menu"
                      onChange={(e) => {
                        handleOnChange(e);
                      }}
                    >
                      {[`Herr`, `Frau`, `Divers`]?.map((data) => {
                        return <option value={data}>{data}</option>;
                      })}
                    </select>
                    <span style={{ color: "#d92c2c" }}>{errors?.menu}</span>
                  </div> */}
                </div>
                <div className="text-area-top-alignment">
                  <input
                    type="text"
                    placeholder="VERFÜGBAR AB"
                    id="genger"
                    name="genger"
                    value={inputValue?.genger}
                    onChange={(e) => {
                      handleOnChange(e);
                    }}
                  />
                  <span style={{ color: "#d92c2c" }}>{errors?.genger}</span>
                </div>
              </div>
            </div>
          </div>
          <DokumenteSection
            inputValue={inputValue}
            errors={errors}
            setErrors={setErrors}
            setInputValue={setInputValue}
          />
        </div>
      </div>
    </div>
  );
}
