import React from "react";
import "./loader.scss";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import Logo from "../../assets/logo/FEFINANCE_LOGO.svg";
import LogoText from "../../assets/logo/logo-text.svg";
export default function Loader() {
  return (
    <div>
      <motion.div
        className="custom-loader-design"
        initial={{ y: "100vh" }}
        animate={{ y: "0" }}
        transition={{
          duration: 1,
        }}
      >
        <div>
          <div
          
            className="logo-center-alignment"
          >
            <img src={Logo} alt="Logo" />
          </div>
          <div
          
            className="logo-text-alignment"
          >
            <img src={LogoText} alt="LogoText" />
          </div>
          </div>
      </motion.div>
    </div>
  );
}
