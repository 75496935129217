import React from "react";
import { NavLink } from "react-router-dom";

export default function PersonalberatungSteper() {
  return (
    <>
      <div className="all-steper-top-rigth-content-alignment">
        <div className="all-steper-grid-alignment">
          <div className="steper-grid">
            <div className="steper-grid-items">
              <a href="#Personal-beratung">
                <span className="steper-all-content-new-alignment">
                  Personalberatung
                </span>
              </a>
            </div>
            <div className="steper-grid-items">
              <div className="steper-round-design">
                <div></div>
              </div>
            </div>
          </div>
          <div className="steper-grid">
            <div className="steper-grid-items">
              <a href="#Karriere">
                <span className="steper-all-content-new-alignment">
                  Karriere. Machen.
                </span>
              </a>
            </div>
            <div className="steper-grid-items">
              <div className="steper-round-design">
                <div></div>
              </div>
            </div>
          </div>
          <div className="steper-grid">
            <div className="steper-grid-items">
              <a href="#Das-Angebot">
                <span className="steper-all-content-new-alignment">
                  Das Angebot{" "}
                </span>
              </a>
            </div>
            <div className="steper-grid-items">
              <div className="steper-round-design">
                <div></div>
              </div>
            </div>
          </div>
          <div className="steper-grid">
            <div className="steper-grid-items">
                <NavLink to="/steper-view">
              <span className="steper-all-content-new-alignment">
                Beratung vereinbaren
              </span>
                </NavLink>
            </div>
            <div className="steper-grid-items">
              <div className="steper-round-design">
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
