import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import MobileViewHeader from "../../MobileViewHeader";

import FinancialGuidanceSteper from "../../steper/financialGuidanceSteper";
import Beratungnach from "./beratungnach";
import "./financialGuidance.scss";

import SicherSeinContent from "./sicherSeinContent";
export default function FinancialGuidance() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      {/* container-lg */}
      <div className="mobile-view-header-show">
        <MobileViewHeader />
      </div>
      <FinancialGuidanceSteper />
      <div className="financial-guidance-all-content-alignment">
        <div className="white-button">
          <NavLink to="/steper-view">
            <button>JETZT BERATEN LASSEN</button>
          </NavLink>
        </div>
        <div className="container-lg">
          <div className="hero-text-style" id="Unternehmen-sberatung">
            <h1>FINANcial Guidance</h1>
            <p>
              Sorgenfrei leben heißt auch: Finanziell nachhaltig aufgestellt
              sein. Wir kümmern uns darum, dass du finanziell sicher aufgestellt
              bist - und das auf lange Sicht.
            </p>
          </div>
          <SicherSeinContent />
          <Beratungnach />
        </div>
      </div>
    </>
  );
}
