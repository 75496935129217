import React from "react";
import "./mitarbeiterSection.scss";
export default function MitarbeiterSection() {
  return (
    <div>
      <div className="mitra-beiter-section-all-content-alignment">
        <div className="section-title" id="Mitarbeiter">
          <h1>Mitarbeiter finden, stärken und binden</h1>
          <p>Ein gutes Unternehmen gibt es nur mit guten Mitarbeitern.</p>
          <p>
            Wir helfen dir nicht nur dabei, diese guten Mitarbeiter zu finden,
            sondern diese langfristig zu binden.
          </p>
          <p>
            Wir beraten umfassend zu unterschiedlichen Möglichkeiten, die für
            eine langfristige, gute Beziehung zwischen Arbeitgeber und
            Arbeitnehmer sorgen.
          </p>
          <p>
            Den Schwerpunkt legen wir dabei auf Mitarbeiterentwicklung und
            -bindung. Denn von diesen Maßnahmen profitieren beide Parteien:
            Unternehmen mit Weiterbildung haben im Schnitt eine 16% höhere
            Arbeitsproduktivität. Gleichzeitig wird die Leistungsbereitschaft
            der Belegschaft um bis zu 57 % angehoben. Und die Verbundenheit zum
            Arbeitgeber gestärkt.
          </p>
          <p>
            Durch unsere Beratung können unsere Kunden außerdem die Löhne und
            Versicherungsleistungen ihrer Mitarbeiter verbessern - denn auch
            finanzielle Benefits sind für Unternehmen eine gute Option, um die
            Zufriedenheit der Angestellten zu erhöhen.
          </p>
        </div>
      </div>
    </div>
  );
}
