import React from "react";
import "./beratungnach.scss";
import ImageShow from "../../../../assets/images/image-show.png";
import { NavLink } from "react-router-dom";
export default function Beratungnach() {
  return (
    <div>
      <div
        className="beratungnach-all-content-alignment"
        id="Beratung-nach-Norm"
      >
        <div className="section-title">
          <h4>Unabhängige Beratung im Sinne der Verbraucher</h4>
          <p>Bei uns stehen die Verbraucher - also du - im Fokus.</p>
          <p>
            Unsere Beratung ist nach DIN-Norm 77230 zertifiziert. Bedeutet: Bei
            uns kannst du dir einer objektiven und individuellen Finanzanalyse
            und -beratung sicher sein.
          </p>
        </div>
        <div className="grid">
          <div className="grid-items">
            <div className="all-buton-alignment">
              <NavLink to="/steper-view">
                <button>Jetzt Potential entfalten</button>
              </NavLink>
              <NavLink to="/termin-vereinbaren">
                <button>Termin vereinbaren</button>
              </NavLink>
            </div>
          </div>
          <div className="grid-items">
            <div className="image-style">
              <img src={ImageShow} alt="ImageShow" />
            </div>
          </div>
        </div>
        <div className="child-text-alignment">
          <p>
            Mit unserer Finanzanalyse verfolgen wir das Ziel, nicht nur deinen
            finanziellen Grundbedarf zu decken, sondern deinen Lebensstandard
            dauerhaft zu verbessern. Schließlich hast du mit Sicherheit Ziele,
            die du erreichen willst. Und dabei helfen wir dir.
          </p>
          <p>
            Übrigens: Wir sind der einzige Anbieter unserer Branche, der
            verbraucherschutz-konform nach Norm berät. Unabhängige und
            individuelle Beratung sind bei uns gelebter Fakt!
          </p>
        </div>
      </div>
    </div>
  );
}
