import React, { useEffect } from "react";
import "./unternehmensberatung.scss";
import UnternehmensberatungSteper from "../../../components/steper/unternehmensberatungSteper";
import SicherSection from "./sicherSection";
import MitarbeiterSection from "./mitarbeiterSection";
import { NavLink } from "react-router-dom";
import MobileViewHeader from "../../MobileViewHeader";

export default function Unternehmensberatung() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <MobileViewHeader />
      <UnternehmensberatungSteper />
      <div className="unternehmensberatung-all-content-alignment">
        <div className="white-button">
          <NavLink to="/steper-view">
            <button>JETZT BERATEN LASSEN</button>
          </NavLink>
        </div>
        <div className="container-lg">
          <div className="hero-text-style" id="Unternehmensberatung">
            <h1>Unternehmensberatung</h1>
            <p>
              Unternehmen, die langfristig erfolgreich sein wollen, sollten die
              Stärkung und Förderung ihrer Belegschaft in den Fokus nehmen.
              Daher helfen wir Unternehmen nicht nur bei der Minimierung von
              Risiken, sondern auf ganzheitlicher Basis. Insbesondere zur
              dauerhaften Verbesserung der Personalsituation.
            </p>
          </div>
          <SicherSection />
          <MitarbeiterSection />
        </div>
      </div>
    </div>
  );
}
