import React, { useEffect } from "react";
import "./karriere.scss";
import CareerImage from "../../../assets/images/career.png";
import KarriereDetails from "./karriereDetails";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { NavLink } from "react-router-dom";
import MobileViewHeader from "../../MobileViewHeader";

export default function Karriere() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <MobileViewHeader />
      <div className="karriere-all-content-alignment1">
        <div className="white-button">
          <NavLink to="/kontakt">
            <button>KONTAKT AUFNEHMEN</button>
          </NavLink>
        </div>
        <div className="container-xl-2">
          <div className="grid karriere-all-content-alignment-tab">
            <div className="grid-items">
              <div className="mobile-view-image-show">
                <img src={CareerImage} alt="CareerImage" />
              </div>
              <h1>Karriere</h1>
              <h2>
                Du hast Lust, Teil unseres Teams zu werden? Dann bewirb dich
                jetzt bei uns!
              </h2>
              <p>
                Was uns ausmacht? Ein tolles Arbeitsklima, flexible
                Arbeitszeiten, individuelle Weiterbildungsmöglichkeiten und ein
                super Gehalt.
              </p>
            </div>
            <div className="grid-items">
              {/* <div className="image-style">
                <img src={CareerImage} alt="CareerImage" />
              </div> */}
            </div>
          </div>
          <KarriereDetails />
        </div>
      </div>
    </div>
  );
}
