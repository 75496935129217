import React, { useEffect, useState, useCallback } from "react";
import "./feFinance.scss";
import ClientImage from "../../../../assets/images/client-image.png";
import NewCallIcon from "../../../../assets/icons/new-call.svg";
import EmailIcon from "../../../../assets/icons/email-icon.svg";
import { ApiGet } from "../../../../helpers/API/ApiData";

export default function FeFinance() {
  const [teamData, setTeamData] = useState([]);

  useEffect(() => {
    showTeamData();
  }, []);

  const showTeamData = () => {
    ApiGet(`team/find`).then((res) => {
      setTeamData(res?.data?.payload?.data);
    });
  };

  return (
    <>
      <div className="fe-finance-all-content-alignment" id="team">
        <div className="page-title">
          <h1>Das sind wir - FE Finance</h1>
        </div>
        <div className="fe-grid">
          {teamData?.map((itam) => {
            return (
              <div className="fe-grid-items">
                <div className="card-image">
                  <img src={itam?.image} alt="ClientImage" />
                </div>
                <div className="card-details">
                  <div className="first-text">
                    <h4>{itam?.teamName}</h4>
                    <p>{itam?.description}</p>
                  </div>
                  <div className="information-all-text-alignment">
                    <div className="information-alignment">
                      <div>
                        <img src={NewCallIcon} alt="NewCallIcon" />
                      </div>
                      <div>
                        <a href="tel:0721931102">
                          <span>{itam?.contactNo}</span>
                        </a>
                      </div>
                    </div>
                    <div className="information-alignment">
                      <div>
                        <img src={EmailIcon} alt="EmailIcon" />
                      </div>
                      <div>
                        <a href="mailto: kommunikation@fe-finance.de?subject = Feedback&body = Message">
                          <span>{itam?.website}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
