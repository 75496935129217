import React, { useEffect, useState, useCallback } from "react";
import "./footer.scss";
import WirMeldenInfo from "./wirMeldenInfo";
import { NavLink } from "react-router-dom";

import FooterLogo from "../../assets/logo/bolg-logo1.svg";
import FacebookIcon from "../../assets/icons/facebook.svg";
import InstagramIcon from "../../assets/icons/insta.svg";
import LinkdinIcon from "../../assets/icons/linkidin.svg";
import EmailIcon from "../../assets/icons/email.svg";
import CallIcon from "../../assets/icons/call.svg";
import RightIcon from "../../assets/icons/right-white.svg";
import { useHistory } from "react-router-dom";

export default function Footer() {
  let history = useHistory();

  const redirectonContact = (e) => {
    history.push("/kontakt");
  };
  const redirectonAbout = (e) => {
    history.push("/uber-uns");
    window.scrollTo({
      top: 50,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="container-footer-layout-change">
        <div className="container-md">
          <>
            <WirMeldenInfo />
          </>
        </div>
      </div>
      <div className="container-md">
        <div className="footer-all-content-alignment">
          <div className="footer-grid">
            <div className="footer-grid-items">
              <div className="footer-logo-center-alignment">
                <img src={FooterLogo} alt="FooterLogo" />
              </div>
            </div>
            <div className="footer-grid-items">
              <div className="footer-child-content-alignment">
                <div className="address-content-style">
                  <p>
                    FE Finance GmbH Zollpfad 4 <br />
                    28217 Bremen
                  </p>
                  <div className="all-icon-alignment">
                    <div>
                      <a
                        href="https://www.facebook.com/FEFinanceGmbH"
                        target="_blank">
                        <img src={FacebookIcon} alt="FacebookIcon" />
                      </a>
                    </div>
                    <div>
                      <a
                        href=" https://www.instagram.com/fe.finance"
                        target="_blank">
                        <img src={InstagramIcon} alt="InstagramIcon" />
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://www.linkedin.com/company/fe-finance-gmbh/"
                        target="_blank">
                        <img src={LinkdinIcon} alt="LinkdinIcon" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="sie-text-content">
                  <h5>So erreichst du uns.</h5>
                  <p>FE Finance GmbH</p>
                  <div className="all-link-icon-content-alignment">
                    <div className="icon-text-alignment">
                      <div>
                        <a href="mailto: kommunikation@fe-finance.de?subject = Feedback&body = Message">
                          <img src={EmailIcon} alt="EmailIcon" />
                        </a>
                      </div>
                      <div>
                        <a href="mailto: kommunikation@fe-finance.de?subject = Feedback&body = Message">
                          <span> kommunikation@fe-finance.de</span>
                        </a>
                      </div>
                    </div>
                    <div className="icon-text-alignment">
                      <div>
                        <img src={CallIcon} alt="CallIcon" />
                      </div>
                      <div>
                        <a href="tel:+49 174 1772709">
                          <span>+49 174 1772709</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="last-content-alignment">
                  <h6>Meld dich bei uns! Wir beraten dich gerne.</h6>
                  <div className="all-icon-text-alignment">
                    <a href="#team">
                      <NavLink to="/uber-uns">
                        <div className="icon-text-alignment">
                          <div>
                            <span>Ansprechpartner </span>
                          </div>
                          <div>
                            <img src={RightIcon} alt="RightIcon" />
                          </div>
                        </div>
                      </NavLink>
                    </a>
                    <NavLink to="/kontakt">
                      <div className="icon-text-alignment">
                        <div>
                          <span>Kontaktformular </span>
                        </div>
                        <div>
                          <img src={RightIcon} alt="RightIcon" />
                        </div>
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copy-right-text">
            <div>
              <NavLink to="/impressum">Impressum</NavLink>
              <a style={{ padding: "0 4px" }}> | </a>
              <NavLink to="/datenschutzerklarung">Datenschutz</NavLink>
            </div>
            <p>Copyright © 2022 FE FINANCE. Alle Rechte vorbehalten.</p>
          </div>
        </div>
      </div>
    </>
  );
}
