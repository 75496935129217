import "./App.css";
import "./styles/mixins/global.scss";
import Routes from "./components";
import "react-toastify/dist/ReactToastify.css";
import MobileShow from "./components/mobileShow/index";
import { Helmet } from "react-helmet";
function App() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FE Finance GmbH</title>
        <meta name="description" content="Ihr Partner in Sachen Finanzen!" />
      </Helmet>
      <div id="second-container">
        <Routes />
      </div>
      <MobileShow />
    </>
  );
}

export default App;
