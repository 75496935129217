import React from "react";
import "./sicherSection.scss";
export default function SicherSection() {
  return (
    <div>
      <div className="sicher-section-all-content-style">
        <div className="section-title" id="Sicher-sein-mitNORM">
          <h3>Dein persönlicher Versicherungsschutz</h3>
          <p>
            Ein guter Versicherungsschutz ist für jedes Unternehmen essenziell.
            Und doch sind viele Unternehmen unterversichert - besonders, wenn es
            um existenzbedrohende Risiken geht.
          </p>
          <p>
            Gemeinsam mit dir arbeiten wir daran, dieses Problem zu beheben.
            Unser Fokus liegt dabei auf Freiberuflern, Gewerbetreibenden,
            Startups, Selbstständigen und KMUs.
          </p>
          <h3> Unser Vorgehen Schritt für Schritt</h3>
          <p>
            Als erstes führen wir ein umfangreiches Check-Up durch, bei dem der
            aktuelle Versicherungsbestand und die Unternehmensspezifika
            aufgenommen werden.
          </p>
          <p>
            Es folgt eine Risikoanalyse, bei der alle erheblichen und nicht
            abgesicherten Risiken für das Unternehmen ausgearbeitet werden.
          </p>
          <p>
            Im Preisvergleich prüfen wir den bestehenden Versicherungsschutz und
            gleichen ihn mit dem aktuellen Marktniveau ab. In diesem Zuge
            arbeiten wir mögliche Beitragsersparnisse heraus.
          </p>
          <p>
            Zu guter Letzt unterbreiten wir dir ein individuelles
            Versicherungsangebot - abgestimmt auf dein Business und deine
            Bedürfnisse. Das garantieren wir dir.*
          </p>
          <p>*DIN-Norm 77235 zertifiziert</p>
        </div>
      </div>
    </div>
  );
}
