import "./wirMeldenInfo.scss";
import React, { useEffect, useState, useCallback } from "react";
import { ApiGet, ApiPost, ApiPut } from "../../../helpers/API/ApiData";
import { toast, ToastContainer } from "react-toastify";
import { NavLink } from "react-router-dom";

import LineLogo from "../../../assets/logo/line-logo.svg";
export default function WirMeldenInfo() {
  const [inputValue, setInputValue] = useState({});
  const [Terms, setTerms] = useState(false);
  const [errors, setErrors] = useState({});

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const regexEmail =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const validateforUserData = () => {
    let isFormValid = true;
    let errors = {};

    if (!inputValue?.fname?.trim() || inputValue?.fname === "") {
      isFormValid = false;
      errors["fname"] = "*Bitte überprüfen Sie ihre Eingabe.";
    }
    if (!inputValue?.lname?.trim() || inputValue?.lname === "") {
      isFormValid = false;
      errors["lname"] = "*Bitte überprüfen Sie ihre Eingabe.";
    }

    if (!inputValue?.phone || inputValue?.phone === "") {
      isFormValid = false;
      errors["phone"] = "*Bitte überprüfen Sie ihre Eingabe.";
    }

    if (
      !inputValue?.email?.trim() ||
      inputValue?.email === "" ||
      regexEmail?.test(inputValue?.email) === false
    ) {
      isFormValid = false;
      errors["email"] = "*Bitte überprüfen Sie ihre Eingabe.";
    }
    if (Terms === false) {
      isFormValid = false;
      errors["check1"] = "*Bitte überprüfen Sie ihre Eingabe.";
    }

    setErrors(errors);
    return isFormValid;
  };

  const bindInput = (value) => {
    var regex = new RegExp("^[^0-9]*$");
    var key = String.fromCharCode(
      !value.charCode ? value.which : value.charCode
    );
    if (regex.test(key)) {
      value.preventDefault();
      return false;
    }
  };

  const submitContact = () => {
    if (validateforUserData()) {
      ApiPost(`contact-form/create`, inputValue)
        .then((res) => {
          toast.success(
            "Vielen Dank, Ihre Daten wurden erfolgreich eingereicht."
          );
          setInputValue({});
          // window.location.reload();
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        })
        .catch((err) => {
          toast.error("Etwas ist schief gelaufen. Bitte versuche es erneut");
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <div
        className="wir-melden-information-content-alignment"
        id="kontakt-aufnehmen"
      >
        <div className="line-logo-alignment">
          <img src={LineLogo} alt="LineLogo" />
        </div>
        <div className="first-grid">
          <div className="first-grid-items">
            <div className="white-box">
              <h2>Wir melden uns bei dir.</h2>
              <div className="input-grid">
                <div className="input-grid-items">
                  <input
                    type="text"
                    placeholder="Vorname"
                    id="fname"
                    name="fname"
                    value={inputValue?.fname}
                    onChange={(e) => {
                      handleOnChange(e);
                    }}
                  />
                  <span style={{ color: "#d92c2c", fontSize: "12px" }}>
                    {errors?.fname}
                  </span>
                </div>
                <div className="input-grid-items">
                  <input
                    type="text"
                    placeholder="Nachname"
                    id="lname"
                    name="lname"
                    value={inputValue?.lname}
                    onChange={(e) => {
                      handleOnChange(e);
                    }}
                  />
                  <span style={{ color: "#d92c2c", fontSize: "12px" }}>
                    {errors?.lname}
                  </span>
                </div>
                <div className="input-grid-items">
                  <input
                    type="text"
                    placeholder="Telefon"
                    id="phone"
                    name="phone"
                    value={inputValue?.phone}
                    onKeyPress={bindInput}
                    onChange={(e) => {
                      handleOnChange(e);
                    }}
                  />
                  <span style={{ color: "#d92c2c", fontSize: "12px" }}>
                    {errors?.phone}
                  </span>
                </div>
                <div className="input-grid-items">
                  <input
                    type="text"
                    placeholder="E-Mail"
                    id="email"
                    name="email"
                    value={inputValue?.email}
                    onChange={(e) => {
                      handleOnChange(e);
                    }}
                  />
                  <span style={{ color: "#d92c2c", fontSize: "12px" }}>
                    {errors?.email}
                  </span>
                </div>
              </div>
              <div className="checkbox-content-alignment">
                <div>
                  <input
                    type="checkbox"
                    checked={Terms}
                    name="check1"
                    id="new-check1"
                    onChange={(e) => setTerms(e.target.checked)}
                  />
                  <label for="new-check1"></label>
                </div>
                <div>
                  <span>
                    Die Datenschutzerklärung habe ich gelesen und akzeptiert.{" "}
                  </span>
                </div>
                {Terms === false && (
                  <span style={{ color: "#d92c2c", fontSize: "12px" }}>
                    {errors?.check1}
                  </span>
                )}
              </div>
              <div className="button-center-alignment">
                <button onClick={() => submitContact()}>ABSCHICKEN</button>
              </div>
            </div>
          </div>
          <div className="first-grid-items">
            <div className="child-menu-all-content-alignment">
              <div>
                <NavLink to="/">
                  <span>Home</span>
                </NavLink>
                <NavLink to="/financial-guidance">
                  <span>Financial Guidance</span>
                </NavLink>
                <NavLink to="/karriereinstitut">
                  <span>Karriereinstitut</span>
                </NavLink>
                <NavLink to="/personalberatung">
                  <span>Personalberatung</span>
                </NavLink>
                <NavLink to="/unternehmensberatung">
                  <span>Unternehemensberatung</span>
                </NavLink>
                <NavLink to="/kontakt">
                  <span>Kontakt</span>
                </NavLink>
                <NavLink to="/karriere">
                  <span>Karriere</span>
                </NavLink>
                <NavLink to="/datenschutzerklarung">
                  <span>Datenschutz</span>
                </NavLink>
                <NavLink to="/impressum">
                  <span>Impressum</span>
                </NavLink>
              </div>
              <div>
                <NavLink to="/uber-uns">
                  <span>Über FE Finance</span>
                </NavLink>
                <NavLink to="/karriere">
                  <span>Karriere bei FE Finance</span>
                </NavLink>
                <NavLink to="/karriere">
                  <span>Initiativbewerbung starten</span>
                </NavLink>
                <NavLink to="/steper-view">
                  <span>Finanzberatung starten</span>
                </NavLink>

                <NavLink to="/uber-uns">
                  <span>Das FE Finance Team</span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
