import React, { useEffect, useState, useCallback } from "react";
import "./firstSteper.scss";
import SteperRightIcon from "../../../../assets/icons/steper-right.svg";
import { useHistory } from "react-router-dom";
export default function FirstSetper(props) {
  const { key, setKey } = props;
  let history = useHistory();

  const redirectHomePage = (e) => {
    history.push("/financial-guidance");
  };
  return (
    <>
      <div className="first-steper-all-content-alignment">
        <div className="text-grid">
          <div className="text-grid-items">
            <div className="text-image-alignment">
              <span>1</span>
              <img src={SteperRightIcon} alt="SteperRightIcon" />
            </div>
          </div>
          <div className="text-grid-items">
            <div className="sub-text-style">
              <h2>Wofür interessierst du dich?</h2>
              <p>
                Wir helfen Spitzensteuerzahlern Ihre staatlichen Abgaben in ein
                stabiles und nachhaltiges Investment zu verwandeln.
              </p>
            </div>
            <div className="all-button-grid-alignment">
              <div className="button-grid">
                <div className="button-grid-items">
                  <button
                    onClick={() => {
                      props.setTabview("two");
                      setKey(["Versicherungsberatung"]);
                    }}>
                    Versicherungsberatung
                  </button>
                </div>
                <div className="button-grid-items">
                  <button
                    onClick={() => {
                      props.setTabview("two");
                      setKey(["Investment Beratung"]);
                    }}>
                    Investmentberatung{" "}
                  </button>
                </div>
                <div className="button-grid-items">
                  <button
                    onClick={() => {
                      props.setTabview("two");
                      setKey(["Finanzierungsberatung"]);
                    }}>
                    Unternehmensberatung
                  </button>
                </div>
                <div className="button-grid-items">
                  <button
                    onClick={() => {
                      props.setTabview("two");
                      setKey(["Personalberatung"]);
                    }}>
                    Personalberatung
                  </button>
                </div>
                <div className="button-grid-items">
                  <button
                    onClick={() => {
                      props.setTabview("two");
                      setKey(["Finanzierungsberatung"]);
                    }}>
                    Finanzierungsberatung
                  </button>
                </div>
                <div className="button-grid-items">
                  <button
                    onClick={() => {
                      props.setTabview("two");
                      setKey(["Karrierecoaching"]);
                    }}>
                    Karrierecoaching
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="button-left-alignment">
          <button onClick={(e) => redirectHomePage(e)}>
            zurück zur Website
          </button>
        </div>
      </div>
    </>
  );
}
