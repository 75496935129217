import React  from "react";
import MobileJson from '../../assets/json/animation.json';
import Lottie from "lottie-react";
const MobileShow = (()=> {
    return(
        <>  
            <div id="mobile-id">
                <div className="lottie-content-alignment">
                    <Lottie animationData={MobileJson}  />
                </div>
            </div>
        </>
    )
})

export default MobileShow;