import React from "react";
import "./sicherSeinContent.scss";
import ChartImage from "../../../../assets/images/chart2.svg";
export default function SicherSeinContent() {
  return (
    <>
      <div className="sicher-sein-content-alignment">
        <h2>Unser Vorgehen</h2>
        <p>
          Eine gute Finanzplanung berücksichtigt immer deine aktuellen
          Lebensumstände. Aber auch deine angestrebten Ziele. Daher kombinieren
          wir Finanz- und Lebensplanung miteinander. So, dass du deine
          finanziellen Mittel jederzeit optimal einsetzt.
        </p>
        <p>
          Natürlich sind wir jederzeit für dich da, wenn du Fragen hast oder
          etwas mal nicht verstehen solltest. Außerdem agieren wir zu jeder Zeit
          transparent und nachvollziehbar. Das ist Finanzberatung auf Augenhöhe.
        </p>
        <p>
          Plus: Wir haben uns dem Maklereid verpflichtet. Wir agieren also
          unabhängig von Banken und Versicherungsunternehmen. So können wir dir
          das gesamte Marktportfolio an Versicherungs- und Finanzlösungen
          aufzeigen - und das beste Angebot für dich auswählen.
        </p>
      </div>
      <div className="chart-section-grid">
        <div className="chart-section-grid-items">
          <div className="chart-image-content-alignment">
            <img src={ChartImage} alt="ChartImage" />
          </div>
        </div>
        <div className="chart-section-grid-items">
          <div className="all-content-left-alignment">
            <div>
              <a>HUMANKAPITAL</a>
              <a>EINKOMMEN</a>
              <a>KONSUM</a>
            </div>
            <div>
              <a>ZIELE/ VERMÖGEN</a>
              <a>KURZFRISTIG</a>
              <a>MITTELFRISTIG</a>
              <a>LANGFRISTIG</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
