import React, { useEffect } from "react";
import "./impressum.scss";
export default function Impressum() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <div className="impressum-content-alignment">
        <div className="container-lg">
          <h1>
            <strong>Impressum</strong>
          </h1>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <p>
            <strong>FE Finance</strong> GmbH
          </p>
          <p>Zollpfad 4</p>
          <p>28217 Bremen</p>
          <p>
            Telefon:&nbsp;<a href="tel: +49 174 1772709"> +49 174 1772709</a>
          </p>
          <p>
            E-Mail:&nbsp;
            <a href="mailto: kommunikation@fe-finance.de">
              {" "}
              kommunikation@fe-finance.de
            </a>
          </p>
          <p>&nbsp;</p>
          <p>
            Gesch&auml;ftsf&uuml;hrer: Patrick Germer, Michael Mues und Antonino
            Chiavetta
          </p>
          <p>Vertretungsberechtigter: Patrick Germer</p>
          <p>
            Handelsregister &ndash; Eintrag: Amtsgericht Bremen, HRB 38344 HB
          </p>
          <p>
            Inhaltlich verantwortlich nach &sect; 18 Abs. 2 MStV: Patrick Germer
          </p>
          <p>UST-Ident-Nr.: DE355183542</p>
          <p>&nbsp;</p>
          <p>
            Erlaubnis nach &sect; 34 d Abs. 1 GewO Berufsbezeichnung:
            Versicherungsmakler; Bundesrepublik Deutschland
            Versicherungsvermittlerregister: www.vermittlerregister.info
            Registrierungsnummer: D-CIRX-MKYXC-84
          </p>
          <p>&nbsp;</p>
          <p>
            Erlaubnis nach &sect; 34 f Abs. 1 GewO Berufsbezeichnung:
            Finanzanlagenvermittler; Bundesrepublik Deutschland
            Versicherungsvermittlerregister: www.vermittlerregister.info
            Registrierungsnummer: D-F-112-Y981-43
          </p>
          <p>&nbsp;</p>
          <p>
            Erlaubnis nach &sect; 34 c Abs. 1 GewO Berufsbezeichnung:
            Immobilienmakler, Darlehnsvermittler, Bautr&auml;ger und Baubetreuer
          </p>
          <p>&nbsp;</p>
          <p>
            Aufsichtsbeh&ouml;rde: Handelskammer Bremen - IHK f&uuml;r Bremen
            und Bremerhaven; Am Markt 13, 28195 Bremen; Tel.: 0421/3637-0; Fax:
            0421/3637-299; E-Mail: service@handelskammer-bremen.de;
            https://www.ihk.de/bremen-bremerhaven/
          </p>
          <p>&nbsp;</p>
          <h3>
            <strong>Berufsrechtliche Regelungen:</strong>
          </h3>
          <p>&nbsp;</p>
          <p>
            34 c, &sect;34 d und &sect; 34 f Gewerbeordnung &sect;&sect; 59-68
            VVG VersVermV Die berufsrechtlichen Regelungen k&ouml;nnen &uuml;ber
            die vom Bundesministerium der Justiz und von der juris GmbH
            betriebenen Internetseite www.gesetze-im-internet.de eingesehen und
            abgerufen werden.
          </p>
          <p>&nbsp;</p>
          <p>
            Die Eintragung im Vermittlerregister kann wie folgt
            &uuml;berpr&uuml;ft werden: Deutsche Industrie- und Handelskammertag
            (DIHK) e.V., Breite Stra&szlig;e 29, 10178 Berlin | Tel.:
            0180-600-585-0* www.vermittlerregister.info Festnetzpreis 0,20
            &euro;/ Anruf; Mobilfunkpreise maximal 0,60 &euro;/ Anruf
          </p>
          <p>&nbsp;</p>
          <p>
            Unser Unternehmen/Der Versicherungsmakler h&auml;lt keine direkte
            oder indirekte Beteiligung an den Stimmrechten oder am Kapital eines
            Versicherungsunternehmens. Ein Versicherungsunternehmen oder
            Mutterunternehmen eines Versicherungsunternehmens h&auml;lt keine
            direkte oder indirekte Beteiligung an den Stimmrechten oder am
            Kapital des Versicherungsmaklers/ unseres Unternehmens.
          </p>
          <p>&nbsp;</p>
          <h3>
            <strong>Anschriften von Schlichtungsstellen</strong>
          </h3>
          <p>&nbsp;</p>

          <p>
            Bundesanstalt f&uuml;r Finanzdienstleistungsaufsicht, Ombudsleute,
            Postfach 13 08, 53003 Bonn www.bafin.de Ombudsstelle f&uuml;r
            Investmentfonds des BVI, Unter den Linden 42, 10117 Berlin
            www.ombudsstelle-investmentfonds.de Ombudsstelle Geschlossene Fonds,
            Invalidenstr. 35, 10115 Berlin{" "}
            <a href="http://www.ombudsstelle-gfonds.de">
              www.ombudsstelle-gfonds.de
            </a>
          </p>
          <p>&nbsp;</p>
          <h3>
            <strong>
              Information zur Teilnahme am Streitbeilegungsverfahren
              gem&auml;&szlig; &sect; 36 Verbraucherstreitbeilegungsgesetz
            </strong>
          </h3>
          <p>&nbsp;</p>
          <p>
            Wir nehmen am Streitbeilegungsverfahren vor folgenden
            Verbraucherschlichtungsstellen teil:
          </p>
          <p>
            Versicherungsombudsmann e.V. Postfach 08 06 32 10006 Berlin
            www.versicherungsombudsmann.de
          </p>
          <p>
            Ombudsmann f&uuml;r die private Kranken- und Pflegeversicherung
            Postfach 06 02 22 10052 Berlin www.pkv-ombudsmann.de
          </p>
          <p>&nbsp;</p>
          <h3>
            <strong>
              Bei T&auml;tigkeit im Bereich der Vermittlung von Investmentfonds
              bitte zus&auml;tzlich die folgende Stelle nennen:
            </strong>
          </h3>
          <p>&nbsp;</p>
          <p>
            Allgemeine Verbraucherschlichtungsstelle des Zentrums f&uuml;r
            Schlichtung e.V. Stra&szlig;burger Stra&szlig;e 8 77694 Kehl am
            Rhein{" "}
            <a href="mailto:mail@verbraucher-schlichter.de">
              mail@verbraucher-schlichter.de
            </a>
          </p>
          <p>&nbsp;</p>
          <h3>
            <strong>
              Beratung und Vermittlung von Finanzinstrumenten nach &sect; 2 Abs.
              6 Nr. 8 KGW
            </strong>
          </h3>
          <p>
            Bei der Beratung zu und der Vermittlung von
            Versicherungsvertr&auml;gen wird die Vertriebsgesellschaft mit der
            Wahrnehmung Ihrer Interessen betraut. Hierunter f&auml;llt die
            Betreuung hinsichtlich von Lebensversicherungen, Sachversicherungen,
            Krankenversicherungen usw. Die Erlaubnis wurde erteilt von der IHK
            des zust&auml;ndigen Landkreises. Die Vertriebsgesellschaft ist im
            Versicherungsvermittlerregister eingetragen. Weitere Infos siehe
            oben.
          </p>
          <p>
            Die Anlageberatung und der Vermittlung von Finanzinstrumenten gem.
            &sect; 2 Abs. 6 Nr. 8 KWG erfolgt aufgrund der erteilten Genehmigung
            nach &sect; 34 f GewO. Der Vermittler ist im Namen und auf Rechnung
            der nachfolgenden Vertriebsgesellschaft t&auml;tig und damit
            Gewerbetreibender nach &sect; 84 HGB. Der Vertragsschluss &uuml;ber
            den Erwerb eines Finanzinstrumentes findet grunds&auml;tzlich
            zwischen Ihnen als Kunden und dem jeweiligen Produktanbieter statt.
            Die Vertriebsgesellschaft hat jedoch die erforderliche Sorgfalt nach
            den Regeln der Finanzanlagenvermittlerverordnung (FinVermV) zu
            ber&uuml;cksichtigen. Insbesondere schuldet sie danach die anlage-
            und anlegergerechte Beratung unter Ber&uuml;cksichtigung Ihrer
            Kenntnisse und Erfahrungen sowie des von Ihnen angebenden
            Anlagezweckes. &Uuml;ber die Pflichten und die weitere
            Zusammenarbeit kann auch ein Vertrag zwischen Kunde und
            Vertriebsgesellschaft geschlossen werden. Die Vertriebsgesellschaft
            ist ferner gehalten dar&uuml;ber auch ein Protokoll zu f&uuml;hren.
          </p>
          <p>&nbsp;</p>
          <h3>
            <strong>
              Information zur Einbeziehung von Nachhaltigkeitsrisiken
            </strong>
          </h3>
          <p>
            Im Rahmen der Beratung werden die wichtigsten Auswirkungen auf
            Nachhaltigkeitsfaktoren einbezogen. Bei der Auswahl von
            Versicherungsgesellschaften und Versicherungsprodukten
            ber&uuml;cksichtigen wir daher die von den Versicherern zum Umgang
            mit Nachhaltigkeitsrisiken zur Verf&uuml;gung gestellten
            Informationen. F&uuml;r deren Richtigkeit ist der Vermittler nicht
            verantwortlich. Zur Zeit kann eine Ber&uuml;cksichtigung auf Grund
            der teilweise noch rudiment&auml;ren Informationen ggf. noch nicht
            umfassend erfolgen.
          </p>
          <p>
            Versicherer, die erkennbar keine Strategie zur Einbeziehung von
            Nachhaltigkeitsrisiken in ihre Investitionsentscheidungen
            einbeziehen, bieten wir ggf. nicht an.
          </p>
          <p>
            Im Rahmen der im Kundeninteresse erfolgenden individuellen Beratung
            stellen wir gesondert dar, wenn die Ber&uuml;cksichtigung der
            Nachhaltigkeitsrisiken bei der Investmententscheidung einen
            erkennbaren Vor- bzw. Nachteil f&uuml;r den individuellen Kunden
            bedeuten. &Uuml;ber die jeweilige Ber&uuml;cksichtigung von
            Nachhaltigkeitsrisiken des jeweiligen Versicherers informiert dieser
            mit dessen vorvertraglichen Informationen. Bei Fragen dazu kann der
            Kunde uns gerne im Vorfeld eines m&ouml;glichen Abschlusses
            ansprechen.
          </p>
          <p>&nbsp;</p>
          <h3>
            <strong>
              Information &uuml;ber Verg&uuml;tungen und Zuwendungen Dritter bei
              der Finanzanlagenvermittlung nach &sect; 12a FinVermV:
            </strong>
          </h3>
          <p>
            Die Verg&uuml;tung des Beraters f&uuml;r eine Anlageberatung oder
            Vermittlung erfolgt im Falle einer Transaktion (Order) durch
            Zuwendungen des Produktgebers bzw. des Vertragspartners aus den
            Kosten des Produkts bzw. der Transaktion (Zuwendungen Dritter).
            Diese Zuwendungen d&uuml;rfen vom Berater als Verg&uuml;tung
            angenommen und behalten werden. Die Verg&uuml;tung f&uuml;r die
            Vermittlung von Finanzprodukten wird nicht von den jeweiligen
            Nachhaltigkeitsrisiken beeinflusst.
          </p>
          <p>&nbsp;</p>
          <h3>
            <strong>Beratung und Vermittlung von Versicherungen</strong>
          </h3>
          <p>
            Bei der Beratung zu und der Vermittlung von
            Versicherungsvertr&auml;gen wird die Vertriebsgesellschaft mit der
            Wahrnehmung Ihrer Interessen betraut. Hierunter f&auml;llt die
            Betreuung hinsichtlich von Lebensversicherungen, Sachversicherungen,
            Krankenversicherungen usw. Die Erlaubnis wurde erteilt von der IHK
            des zust&auml;ndigen Landkreises. Die Vertriebsgesellschaft ist im
            Versicherungsvermittlerregister eingetragen. Weitere Infos siehe
            oben.
          </p>
          <p>&nbsp;</p>
          <h3>
            <strong>Beratung und Vermittlung von Direktcontainern</strong>
          </h3>
          <p>
            Soweit dem Kunden Anlagegesch&auml;fte in Containern angeboten
            werden, handelt es sich nicht um regulierte Gesch&auml;fte in
            Finanzinstrumenten. Insbesondere finden die Regelungen des WphG und
            des KWG in diesem Zusammenhang keine Anwendung.
          </p>
          <h3>
            <strong>
              Der Versicherungsmakler ber&auml;t den Kunden und wird bei seiner
              T&auml;tigkeit gegen&uuml;ber dem Versicherungsnehmen stets
              ehrlich, redlich und professionell in dessen bestm&ouml;glichem
              Interesse handeln.
            </strong>
          </h3>
          <p>&nbsp;</p>
          <h4>
            <strong>Verg&uuml;tung des Versicherungsmaklers</strong>
          </h4>
          <p>
            Soweit nicht etwas anderes vereinbart ist, erh&auml;lt der Makler
            f&uuml;r die Vermittlungs- und Beratungst&auml;tigkeit eine Courtage
            in &uuml;blicher und von der Pr&auml;mie abh&auml;ngiger H&ouml;he.
            Die Courtage ist von den Versicherern bereits bei der
            Pr&auml;mienkalkulation ber&uuml;cksichtigt.
          </p>
          <p>
            Abweichend hiervon kann auf Wunsch des Kunden die direkte
            Verg&uuml;tung des Versicherungsmaklers durch ein vom Kunden zu
            zahlendes Honorar vereinbart werden. Die von dem Versicherungsmakler
            betreuten Versicherungsvertr&auml;ge werden dann auf der Basis
            courtagefreier Tarife abgeschlossen (sog. Nettoisierung). Die vom
            Versicherer berechnete Pr&auml;mie enth&auml;lt dann nur noch die
            Gefahrtragungspr&auml;mie, Verwaltungskosten sowie den Gewinnanteil
            des Versicherers. Eine solche gesonderte Verg&uuml;tungsvereinbarung
            muss in jedem Fall ausdr&uuml;cklich vereinbart werden.&nbsp; Andere
            Zuwendungen erh&auml;lt der Versicherungsmakler nicht.
          </p>
        </div>
      </div>
    </div>
  );
}
