import React, { useEffect, useState, useCallback } from "react";
import MainLogo from "../../../assets/logo/FEFinance_2023.svg";
import XmldIcon from "../../../assets/images/abc.svg";
import InformationIcon from "../../../assets/icons/information.svg";
import { toast, ToastContainer } from "react-toastify";
import { ApiGet, ApiPost, ApiPut } from "../../../helpers/API/ApiData";
import { useHistory } from "react-router-dom";

import "./terminVereinbaren.scss";
import { NavLink } from "react-router-dom";
import { isReturnStatement } from "typescript";
export default function TerminVereinbaren() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const history = useHistory();

  const [inputValue, setInputValue] = useState({});
  const [errors, setErrors] = useState({});

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const validateforUserData = () => {
    let isFormValid = true;
    let errors = {};
    if (!inputValue?.name?.trim() || inputValue?.name === "") {
      isFormValid = false;
      errors["name"] = "*Bitte überprüfen Sie ihre Eingabe.";
    }
    if (!inputValue?.time?.trim() || inputValue?.time === "") {
      isFormValid = false;
      errors["time"] = "*Bitte überprüfen Sie ihre Eingabe.";
    }

    if (!inputValue?.phone || inputValue?.phone === "") {
      isFormValid = false;
      errors["phone"] = "*Bitte überprüfen Sie ihre Eingabe.";
    }
    if (
      !inputValue?.email?.trim() ||
      inputValue?.email === "" ||
      regexEmail?.test(inputValue?.email) === false
    ) {
      isFormValid = false;
      errors["email"] = "*Bitte überprüfen Sie ihre Eingabe.";
    }

    // if (!inputValue?.message?.trim() || inputValue?.message === "") {
    //   isFormValid = false;
    //   errors["message"] = "*Bitte geben Sie Beschreibung ein";
    // }

    setErrors(errors);
    return isFormValid;
  };
  const submitForm = () => {
    if (validateforUserData()) {
      let data = {
        name: inputValue?.name,
        time: inputValue?.time,
        phone: inputValue?.phone,
        email: inputValue?.email,
        message: inputValue?.message,
      };
      ApiPost(`appointment/create`, data)
        .then((res) => {
          setInputValue({});

          setTimeout(() => {
            toast.success(
              "Vielen Dank, Ihre Daten wurden erfolgreich eingereicht."
            );
          }, 200);
          history.push("/financial-guidance");
          // window.location.reload();
        })
        .catch((error) => {
          toast.error("Etwas ist schief gelaufen. Bitte versuche es erneut");
        });
    }
  };
  const bindInput = (value) => {
    var regex = new RegExp("^[^0-9]*$");
    var key = String.fromCharCode(
      !value.charCode ? value.which : value.charCode
    );
    if (regex.test(key)) {
      value.preventDefault();
      return false;
    }
  };

  return (
    <>
      <div className="main-section-steper-content-alignment">
        <ToastContainer />

        <div className="grid">
          <div className="grid-items">
            <div className="terminal-all-content-center-alignment">
              <div>
                <div className="terminal-all-content-box">
                  <h1>Termin vereinbaren</h1>
                  <p> So können wir dich erreichen</p>
                  <div className="text-grid">
                    <div className="text-grid-items">
                      <div className="span-text-alignment">
                        <span>Wie lautet dein Name?</span>
                      </div>
                      <input
                        type="text"
                        placeholder="Vor-und Nachname / Firmenname"
                        id="name"
                        name="name"
                        value={inputValue?.name}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                      <span style={{ color: "#d92c2c" }}>{errors?.name}</span>
                    </div>
                    <div className="text-grid-items">
                      <div className="span-text-alignment">
                        <span>Deine Erreichbarkeit</span>
                        <div className="container-tool">
                          <div className="tooltip">
                            <img src={InformationIcon} alt="InformationIcon" />
                            <h6 className="tooltiptext">
                              wahle ein Zeitfenstr,in dem wir Wir dich erreichen
                              konnen
                            </h6>
                          </div>
                        </div>
                      </div>
                      {/* <input
                        type="text"
                        placeholder=" Firmenname"
                        id="time"
                        name="time"
                        value={inputValue?.time}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      /> */}
                      <select
                        name="time"
                        id="time"
                        onChange={(e) => {
                          handleOnChange(e);
                        }}>
                        {[
                          `09:00-11:00 Uhr`,
                          `11:00-13:00 Uhr`,
                          `13:00-15.00 Uhr`,
                          `15:00-17:00 Uhr`,
                          `17:00-19:00 Uhr`,
                          `19:00-21:00 Uhr`,
                        ]?.map((data) => {
                          return <option value={data}>{data}</option>;
                        })}
                      </select>

                      <span style={{ color: "#d92c2c" }}>{errors?.time}</span>
                    </div>
                    <div className="text-grid-items">
                      <div className="two-section-alignment">
                        <div className="span-text-alignment">
                          <span>Deine Telefonnummer</span>
                          <div className="container-tool">
                            <div className="tooltip">
                              <img
                                src={InformationIcon}
                                alt="InformationIcon"
                              />
                              <h6 className="tooltiptext">
                                telefonische Ruckfragen beshieunigen die
                                Terminfindung.
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="radio-text-button-alignment">
                          <div>
                            <input
                              className="input-radio"
                              type="checkbox"
                              id="test"
                            />
                            <label for="test">
                              {" "}
                              <span>bevorzugter Kontakt</span>
                            </label>
                          </div>
                          <div></div>
                        </div>
                      </div>
                      <input
                        type="text"
                        placeholder="Deine Telefonnummer"
                        id="phone"
                        name="phone"
                        onKeyPress={bindInput}
                        value={inputValue?.phone}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                      <span style={{ color: "#d92c2c" }}>{errors?.phone}</span>
                    </div>
                    <div className="text-grid-items">
                      <div className="two-section-alignment">
                        <div className="span-text-alignment">
                          <span>Deine Mailadresse</span>
                          <div className="container-tool">
                            <div className="tooltip">
                              <img
                                src={InformationIcon}
                                alt="InformationIcon"
                              />
                              <h6 className="tooltiptext">
                                wir senden dir ausschliebtich E-Mails zur
                                terminbestatigung
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="radio-text-button-alignment">
                          <div>
                            <input
                              className="input-radio"
                              type="checkbox"
                              id="test1"
                            />
                            <label for="test1">
                              {" "}
                              <span>bevorzugter Kontakt</span>
                            </label>
                          </div>
                          <div></div>
                        </div>
                      </div>
                      <input
                        type="text"
                        placeholder="Deine Mailadresse"
                        id="email"
                        name="email"
                        value={inputValue?.email}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                      <span style={{ color: "#d92c2c" }}>{errors?.email}</span>
                    </div>
                    <div className="text-grid-items">
                      <div className="span-text-alignment">
                        <span>
                          Dein Anliegen{" "}
                          <a style={{ color: "#fff" }}> - optional</a>
                        </span>
                      </div>
                      <textarea
                        placeholder="Hier können Sie uns zusätzliche Anliegen schildern oder Fragen stellen."
                        id="message"
                        name="message"
                        value={inputValue?.message}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                      {/* <span style={{ color: "#d92c2c" }}>
                        {errors?.message}
                      </span> */}
                    </div>
                    <div className="text-grid-items">
                      <div className="all-checkbox-content-alignment">
                        <div className="checkbox-align">
                          <div>
                            <input type="checkbox" id="Donuts1" />
                            <label htmlFor="Donuts1">
                              <span>
                                Ja, Ich möchte gerne den Newsletter abonnieren,
                                um über aktuelle Produkte und Leistungen
                                informiert zu werden.
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="checkbox-align">
                          <div>
                            <input type="checkbox" id="Donuts2" />
                            <label htmlFor="Donuts2">
                              <span>
                                Ich stimme einer Kontaltaufnahme durch einen
                                Berater in meiner Nähe zu und bin damit
                                einverstanden, dass meine Daten nur für diesen
                                Zweck weltergeleltet werden.
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="checkbox-align">
                          <div>
                            <input type="checkbox" id="Donuts3" />
                            <label htmlFor="Donuts3">
                              <span>
                                Ich habe die AGB und Datensechutzbestimmungen
                                gelesen und akzeptlert.
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="web-last-button-right-alignment">
                  <button onClick={() => submitForm()}>Weiter</button>
                </div>
              </div>
            </div>
          </div>
          <div className="grid-items">
            <div>
              <div className="logo-center-alignment">
                <NavLink to="/">
                  <img src={MainLogo} alt="MainLogo" />
                </NavLink>
              </div>
              <div className="button-center-alignment">
                <button>Termin vereinbaren</button>
              </div>
              <div className="text-style">
                <p>verbraucherschutzkonform nach</p>
              </div>
              <div className="iamge-center-alignment">
                <img src={XmldIcon} alt="XmldIcon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
