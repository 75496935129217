import React, { useState, useEffect } from "react";
import "./steper.scss";
import MainLogo from "../../../assets/logo/main-logo.svg";
import XmldIcon from "../../../assets/images/abc.svg";
import FirstSetper from "./firstSteper";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import SecSteper from "./secSteper";
import ThreeSteper from "./threeSteper";
import ForthSteper from "./forthSteper";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";

export default function Steper() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  let history = useHistory();
  const [tabview, setTabview] = useState("first");

  const [key, setKey] = useState("");
  const [secoundsetp, setSecoundsetp] = useState("");
  const [thirdsetp, setThirdsetp] = useState("");

  const openAppointment = (e) => {
    history.push("/termin-vereinbaren");
  };

  return (
    <div>
      <motion.div
        initial={{ y: "100vh" }}
        animate={{ y: "0" }}
        transition={{
          duration: 1.3,
        }}
      >
        <div className="main-section-steper-content-alignment">
          <div className="grid">
            <div className="grid-items">
              <div className="steper-type-content-alignment">
                <div className="steper-type-box">
                  {tabview === "first" && (
                    <FirstSetper
                      setTabview={setTabview}
                      tabview={tabview}
                      key={key}
                      setKey={setKey}
                    />
                  )}
                  {tabview === "two" && (
                    <SecSteper
                      setTabview={setTabview}
                      tabview={tabview}
                      setKey={setSecoundsetp}
                    />
                  )}
                  {tabview === "three" && (
                    <ThreeSteper
                      setTabview={setTabview}
                      tabview={tabview}
                      setKey={setThirdsetp}
                    />
                  )}
                  {tabview === "four" && (
                    <ForthSteper
                      setTabview={setTabview}
                      tabview={tabview}
                      setKey={setKey}
                      setp1={key}
                      setp2={secoundsetp}
                      setp3={thirdsetp}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="grid-items">
              <div>
              <div className="logo-center-alignment">
                <NavLink to="/">
                  <img src={MainLogo} alt="MainLogo" />
                </NavLink>
              </div>
                <div className="button-center-alignment">
                  <button onClick={(e) => openAppointment(e)}>
                    Termin vereinbaren
                  </button>
                </div>
                <div className="text-style">
                  <p>verbraucherschutzkonform nach</p>
                </div>
                <div className="iamge-center-alignment">
                  <img src={XmldIcon} alt="XmldIcon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}
