import React from "react";
import "./threeSteper.scss";
import SteperRightIcon from "../../../../assets/icons/steper-right.svg";
import TrueIcon from "../../../../assets/icons/true-icon.svg";
import RightIcon from "../../../../assets/icons/black-right.svg";
export default function ThreeSteper(props) {
  const { setKey, tabview } = props;

  return (
    <div>
      <div className="first-steper-all-content-alignment">
        <div className="text-grid">
          <div className="text-grid-items">
            <div className="text-image-alignment">
              <span>3</span>
              <img src={SteperRightIcon} alt="SteperRightIcon" />
            </div>
          </div>
          <div className="text-grid-items">
            <div className="sub-text-style">
              <h2> Wurden Sie Verbraucherschutz konform beraten?</h2>
              <p>
                Wir helfen Spitzensteuerzahlern Ihre staatlichen Abgaben in ein
                stabiles und nachhaltiges Investment zu verwandeln.
              </p>
            </div>
            <div className="auto-fill-all-button">
              <button
                onClick={() => {
                  props.setTabview("four");
                  setKey(["JA"]);
                }}
              >
                <span>JA</span>
                <img src={TrueIcon} alt="TrueIcon" />
              </button>
              <button
                onClick={() => {
                  props.setTabview("four");
                  setKey(["NEIN"]);
                }}
              >
                <span>NEIN</span>
                <img src={RightIcon} alt="RightIcon" />
              </button>
              <button
                onClick={() => {
                  props.setTabview("four");
                  setKey(["WEIß ICH NCIHT"]);
                }}
              >
                WEIß ICH NCIHT
              </button>
            </div>
          </div>
        </div>
        <div className="button-left-alignment">
          <button onClick={() => props.setTabview("two")}>zurück</button>
        </div>
      </div>
    </div>
  );
}
