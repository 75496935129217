import React, { useEffect, useState, useCallback } from "react";
import "./karriereDetails.scss";
import SearchIcon from "../../../../assets/icons/search.svg";
import RightIcon from "../../../../assets/icons/right-md.svg";
import { NavLink } from "react-router-dom";
import { ApiGet } from "../../../../helpers/API/ApiData";
import { toast, ToastContainer } from "react-toastify";

export default function KarriereDetails() {
  const [jobData, setJobData] = useState([]);
  const [search, setSearch] = useState("");
  const [jobDataSearch, setJobDataSearch] = useState(0);
  const [jobDataCity, setJobDataCity] = useState([]);
  const [cityDataCount, setCityDataCount] = useState(0);


  useEffect(() => {
    showAllJob();
    cityData();
  }, []);

  useEffect(() => {
    showAllJob();
    cityData();
  }, [search]);

  const cityData = () => {
    if (!search) {
      // ApiGet(`city/find`)
      ApiGet(`job/find`)
        .then((res) => {
          if (res?.data?.payload?.data) {
            setJobDataCity(res?.data?.payload?.data);
            if (res?.data?.payload?.count) {
              setCityDataCount(res?.data?.payload?.count);
            }
          }
        })
        .catch((err) => {
        });
    } else {
      ApiGet(`job/find?letter=${search}`)
        .then((res) => {
          setJobDataCity(res?.data?.payload?.data);
          setCityDataCount(res?.data?.payload?.count);
        })
        .catch((err) => {
        });
    }
  };

  const showAllJob = () => {
    if (!search) {
      ApiGet(`city/find`)
        .then((res) => {
          if (res?.data?.payload?.data) {
            setJobData(res?.data?.payload?.data);
            if (res?.data?.payload?.count) {
              setJobDataSearch(res?.data?.payload?.count);
            }
          }
        })
        .catch((err) => {
          toast.error("something went wrong please try again");
        });
    } else {
      ApiGet(`city/find?letter=${search}`)
        .then((res) => {
          setJobData(res?.data?.payload?.data);
        })
        .catch((err) => {
          toast.error("something went wrong please try again");
        });
    }
  };

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };

  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }

  return (
    <>
      <ToastContainer />
      <div>
        <div className="karriere-details-all-content-alignment">
          <div className="firt-title-alignment">
            <p>
              Jobs: {cityDataCount ? cityDataCount : 0}| Standorte:{" "}
              {jobDataSearch ? jobDataSearch : 0} | Kategorien: 1
            </p>
          </div>
          <div className="globally-search">
            <div className="input-relative">
              <input
                type="text"
                placeholder="Stellen durchsuchen"
                onChange={(e) => handleSearch(e)}
              />
              <div className="icon-alignment">
                <img src={SearchIcon} alt="SearchIcon" />
              </div>
            </div>
          </div>
          <>
            {jobData?.map((data) => {
              return (
                <>
                  <div className="left-right-content-new">
                    {data?.job?.length > 0 && (
                      <>
                        <div className="job-title-alignment">
                          <p className>{data?.cityName}</p>
                        </div>

                        <div className="all-job-content-alignment">
                          {data?.job?.map((item) => {
                            return ( 
                              <>
                                <div className="job-list">
                                  <NavLink
                                    to={`/karriere-information/${item?._id}`}
                                  >
                                    <div>
                                      <h5>{item?.jobName}</h5>
                                      <span>
                                        {item?.position}, {item?.jobTime}
                                      </span>
                                    </div>
                                    <div>
                                      <img src={RightIcon} alt="RightIcon" />
                                    </div>
                                  </NavLink>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                </>
              );
            })}
          </>
        </div>
      </div>
    </>
  );
}
