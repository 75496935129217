import React from "react";
import "./header.scss";
import FeFinanceLogo from "../../assets/logo/fefinance1.svg";
import FeWerder from "../../assets/logo/FE-F.svg";

import { NavLink } from "react-router-dom";
import HeaderArrow from "../../assets/icons/header-arrow.svg";

export default function Header() {
  return (
    <div>
      <div className="globally-set-header-design">
        <div className="animate__slideInUp animate__animated wow">
          <div className="logo">
            <NavLink to="/">
            <a>
            <div className="two-contnet-alignment">
              <img
              className="image-first"
              src={FeFinanceLogo} alt="FeFinanceLogo"
              />
              <img
              className="sec-first"
                src={FeWerder}
                alt=""
              />
              </div>
            </a>
            </NavLink>
          </div>
          <div className="menu-alignment">
            <ul>
              <NavLink to="/financial-guidance">
                <li>
                  <img
                    className={
                      window.location.pathname === "/financial-guidance"
                        ? "header-arrow-show"
                        : "header-arrow-hidden"
                    }
                    src={HeaderArrow}
                    alt="HeaderArrow"
                  />
                  FINANCIAL GUIDANCE
                </li>
              </NavLink>
              <NavLink to="/karriereinstitut">
                <li>
                  <img
                    className={
                      window.location.pathname === "/karriereinstitut"
                        ? "header-arrow-show"
                        : "header-arrow-hidden"
                    }
                    src={HeaderArrow}
                    alt="HeaderArrow"
                  />
                  KARRIEREINSTITUT
                </li>
              </NavLink>
              <NavLink to="/personalberatung">
                <li>
                  <img
                    className={
                      window.location.pathname === "/personalberatung"
                        ? "header-arrow-show"
                        : "header-arrow-hidden"
                    }
                    src={HeaderArrow}
                    alt="HeaderArrow"
                  />
                  PERSONALBERATUNG
                </li>
              </NavLink>
              <NavLink to="/unternehmensberatung">
                <li>
                  <img
                    className={
                      window.location.pathname === "/unternehmensberatung"
                        ? "header-arrow-show"
                        : "header-arrow-hidden"
                    }
                    src={HeaderArrow}
                    alt="HeaderArrow"
                  />
                  UNTERNEHMENSBERATUNG
                </li>
              </NavLink>
              <a href="http://fe-finance.solar-pvcheck.de/" target="_blank">
                          <li>
                          <img
                              className="header-arrow-hidden"
                              src={HeaderArrow}
                              alt="HeaderArrow"
                            />
                            PV CHECK</li>
                        </a>
            </ul>
            <ul>
              <NavLink to="/kontakt">
                <li>
                  <img
                    className={
                      window.location.pathname === "/kontakt"
                        ? "header-arrow-show"
                        : "header-arrow-hidden"
                    }
                    src={HeaderArrow}
                    alt="HeaderArrow"
                  />
                  KONTAKT
                </li>
              </NavLink>
              <NavLink to="/karriere">
                <li>
                  <img
                    className={
                      window.location.pathname === "/karriere"
                        ? "header-arrow-show"
                        : "header-arrow-hidden"
                    }
                    src={HeaderArrow}
                    alt="HeaderArrow"
                  />
                  KARRIERE
                </li>
              </NavLink>
              <NavLink to="/uber-uns">
                <li>
                  <img
                    className={
                      window.location.pathname === "/uber-uns"
                        ? "header-arrow-show"
                        : "header-arrow-hidden"
                    }
                    src={HeaderArrow}
                    alt="HeaderArrow"
                  />
                  ÜBER FE FINANCE
                </li>
              </NavLink>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
