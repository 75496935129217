import React from "react";
import "./karriereContent.scss";
import VectoreImage from "../../../../assets/images/career134.png";
export default function KarriereContent() {
  return (
    <>
      <div className="karriere-all-content-alignment">
        <div className="grid">
          <div className="grid-items">
            <div className="text-style">
              <p>
                Die beste Investition ist die in sich selbst - sie bringt die
                höchsten Zinsen.“
              </p>
              <span>- Benjamin Franklin</span>
            </div>
          </div>
          <div className="grid-items">
            <div className="iamge-style">
              <img src={VectoreImage} alt="VectoreImage" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
