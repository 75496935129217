import React, { useEffect, useState, useCallback } from "react";
import { NavLink, useParams } from "react-router-dom";
import LeftIcon from "../../../assets/icons/left-whtie.svg";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import "./karriereInformation.scss";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import ReactHtmlParser from "html-react-parser";
import { ApiGet } from "../../../helpers/API/ApiData";
import MobileViewHeader from "../../MobileViewHeader";
export default function KarriereInformation() {
  let history = useHistory();
  const [jobData, setJobData] = useState({});
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const { id } = useParams();

  const showAllJob = () => {
    ApiGet(`job/find?id=${id}`)
      .then((res) => {
        if (res?.data?.payload?.data?.length) {
          let jobDataResponse =
            res?.data?.payload?.data[res?.data?.payload?.data?.length - 1];
          setJobData({ ...jobDataResponse });
        }
      })
      .catch((err) => {
        toast.error("something went wrong please try again");
      });
  };
  useEffect(() => {
    if (id) {
      showAllJob();
    }
  }, [id]);

  const redirectBackPage = () => {
    history.push("/karriere");
  };

  const goToNext = () => {
    history.push({ pathname: "/apply", state: id });
  };
  console.log("jobData?.advantages", jobData?.advantages);
  return (
    <div>
      <MobileViewHeader />
      <div className="karriere-information-content-alignment-page-layout">
        <div className="white-button">
          <NavLink to="/kontakt">
            <button>KONTAKT AUFNEHMEN</button>
          </NavLink>
        </div>
        <div className="container-xl-2">
          <div className="back-button-content-alignment">
            <img src={LeftIcon} alt="LeftIcon" onClick={redirectBackPage} />
            <span>Stellen durchsuchen </span>
          </div>
          <div className="information-new-list-content-left-right-content-alignment">
            <div className="job-list-text">
              <h2>Financial Guide</h2>
              <p>
                {jobData?.position}, {jobData?.jobTime} ·{" "}
                {jobData?.cityId?.cityName}
              </p>
            </div>

            <div className="all-text-style">
              <div className="text-style">
                <h5>Deine Aufgaben</h5>
                {/* <ul>

                  {console.log("sdfsdf",jobData?.advantages)}
                  {jobData?.advantages && (

                  <li>{ReactHtmlParser(jobData?.advantages)}</li>
                  )}
                </ul> */}

                <ul>
                  <li>
                    Du fungierst nicht nur als Guide im Bereich Finanzen für
                    deine Mandanten, sondern unterstützt sie auch bei
                    persönlichen und beruflichen Anliegen.
                  </li>
                  <li>
                    Du baust deinen eigenen Kundenstamm auf und bietest
                    umfassende Beratung
                  </li>
                  <li>
                    Du nimmst an kostenfreien Schulungen zur persönlichen und
                    beruflichen Entwicklung teil.
                  </li>
                </ul>
              </div>
              <div className="text-style">
                <h5>Deine Kompetenzen</h5>
                <ul>
                  <li>
                    Du hast den Wunsch, beruflich voranzukommen und persönlichen
                    Erfolg zu erzielen.
                  </li>
                  <li>
                    Du zeichnest dich durch hohe Leistungsbereitschaft und
                    selbstständiges Arbeiten aus.
                  </li>
                  <li>Du strebst kontinuierliche Weiterentwicklung an.</li>
                  <li>
                    Du bist ein Teamplayer und schätzt die Zusammenarbeit mit
                    Menschen.
                  </li>
                  <li>
                    Du bist offen, kommunikativ und hast einfach Lust auf
                    FErtrieb.
                  </li>
                </ul>
              </div>
              <div className="text-style">
                <h5>Das bieten wir</h5>
                <ul>
                  <li>
                    Attraktive Verdienstmöglichkeiten (durchschnittlich 113.000
                    €/Jahr nach dreijähriger Zugehörigkeit).
                  </li>
                  <li>Maklerstatus mit wiederkehrender Betreuungsvergütung.</li>
                  <li>
                    Flexibilität zwischen Anstellung und Selbstständigkeit oder
                    einer Kombination aus beiden.
                  </li>
                  <li>
                    Zugang zu einem innovativen Beratungsnetzwerk von höchster
                    Qualität.
                  </li>
                  <li>
                    Unterstützung durch unser Netzwerk für nachhaltige
                    Kundengewinnung.
                  </li>
                  <li>
                    Branchenführende Aus- und Weiterbildungsmöglichkeiten.
                  </li>
                  <li>
                    Spezialisierte Karrierewege im Vergleich zu herkömmlichen
                    Finanzdienstleistern.
                  </li>
                </ul>
              </div>
              <div className="text-style">
                <h5>KONTAKT</h5>
                <ul style={{ padding: "0" }}>
                  <li style={{ listStyleType: "none" }}>{jobData?.contact}</li>
                </ul>
              </div>

              <div className="text-style">
                <h5>ÜBER UNS</h5>
                <ul style={{ padding: "0" }}>
                  <li style={{ listStyleType: "none" }}>
                    <p>{jobData?.about}</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="green-button-center-alignment">
              <button onClick={goToNext}>Jetzt bewerben</button>
            </div>
          </div>
          {/* <div className="karrier-information-all-content-new-alignment">
            <div className="first-text-style">
              <h2>Stellenanzeige </h2>
              <h2>Financial Guide I Standortleiter (m/w/d)</h2>
            </div>
            <div className="first-text-component-style">
              <h3>Dein Aufgabenbereich</h3>
              <p>
                  Du baust dir einen eigenen Mandantenstamm auf und führst Beratungsgespräche mit 
                  eigenständiger Guidance in finanziellen Fragestellungen. 
              </p>
              <p>
                Die Koordinierung deiner Termine als auch die Pflege deiner Kontakte gehört zu deinen 
                täglichen Aufgaben, um für eine langfristige Zusammenarbeit mit deinen Mandanten zu 
                sorgen. Für deine Mandanten bist du nicht nur Guide im Bereichen Finanzen sondern 
                auch in Bezug auf persönliche- sowie karriererelevante Fragen. 
              </p>
            </div>
            <div className="ul-list-type-text">
              <h4>Deine Kompetenzen</h4>
              <ul>
                <li>du strebst nach persönlichem Erfolg</li>
                <li>du besitzt eine hohe Leistungsbereitschaft</li>
                <li>du besitzt eine eigenverantwortliche Arbeitsweise</li>
                <li>du bist lernbereit</li>
                <li>du hast Spaß an der Arbeit mit Menschen und im Team</li>
                <li>Du bist bereit, nach unseren Unternehmenswerten zu arbeiten? Diese sind:</li>
              </ul>
              <div className="left-side-minimun-text-style">
                <span>- Mandant zuerst </span>
                <span>- Integrität</span>
                <span>- Objektivität</span>
                <span>- Fairness</span>
                <span>- Professionalität</span>
                <span>- Kompetenz</span>
                <span>- Vertraulichkeit</span>
                <span>- Sorgfaltspflicht</span>
              </div>
              <div className="dummy-text-style">
                <p>Findest du dich darin wieder?</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
