import React, { useEffect, useState, useCallback } from "react";
import "./kontaktForm.scss";
import { toast, ToastContainer } from "react-toastify";
import { ApiGet, ApiPost, ApiPut } from "../../../../helpers/API/ApiData";

export default function KontaktForm() {
  const [inputValue, setInputValue] = useState({});
  const [inputValueForRadio, setInputValueForRadio] = useState([]);
  const [errors, setErrors] = useState({});

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleOnChangeForRadio = (e) => {
    const { name, value } = e.target;
    setInputValueForRadio({ ...inputValueForRadio, [name]: value });
  };

  const regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const validateforUserData = () => {
    let isFormValid = true;
    let errors = {};
    // if (!inputValue?.compnayName?.trim() || inputValue?.compnayName === "") {
    //   isFormValid = false;
    //   errors["compnayName"] = "*Bitte überprüfen Sie ihre Eingabe.";
    // }
    if (!inputValue?.fname?.trim() || inputValue?.fname === "") {
      isFormValid = false;
      errors["fname"] = "*Bitte überprüfen Sie ihre Eingabe.";
    }
    if (!inputValue?.lname?.trim() || inputValue?.lname === "") {
      isFormValid = false;
      errors["lname"] = "*Bitte überprüfen Sie ihre Eingabe.";
    }
    if (
      !inputValue?.email?.trim() ||
      inputValue?.email === "" ||
      regexEmail?.test(inputValue?.email) === false
    ) {
      isFormValid = false;
      errors["email"] = "*Bitte überprüfen Sie ihre Eingabe.";
    }

    if (!inputValue?.phone || inputValue?.phone === "") {
      isFormValid = false;
      errors["phone"] = "*Bitte überprüfen Sie ihre Eingabe.";
    }
    if (!inputValue?.description?.trim() || inputValue?.description === "") {
      isFormValid = false;
      errors["description"] = "*Bitte überprüfen Sie ihre Eingabe.";
    }

    setErrors(errors);
    return isFormValid;
  };

  const submitContactForm = () => {
    if (validateforUserData()) {
      let data = {
        // compnayName: inputValue?.compnayName,
        fname: inputValue?.fname,
        lname: inputValue?.lname,
        email: inputValue?.email,
        phone: inputValue?.phone,
        option: Object.keys(inputValueForRadio),
        description: inputValue?.description,
      };
      ApiPost(`contact/create`, data)
        .then((res) => {
          toast.success(
            "Vielen Dank, Ihre Daten wurden erfolgreich eingereicht."
          );
          setInputValue({});
          setInputValueForRadio({});
          window.location.reload();
        })
        .catch((error) => {
          toast.error("Etwas ist schief gelaufen. Bitte versuche es erneut");
        });
    }
  };

  const bindInput = (value) => {
    var regex = new RegExp("^[^0-9]*$");
    var key = String.fromCharCode(
      !value.charCode ? value.which : value.charCode
    );
    if (regex.test(key)) {
      value.preventDefault();
      return false;
    }
  };
  return (
    <>
      <ToastContainer />

      <div className="kontakt-form-all-content-alignment" id="Karte">
        <div className="grid">
          <div className="grid-items">
            {/* <div className="input">
              <input
                type="text"
                placeholder="FIRMA (optional)"
                id="compnayName"
                name="compnayName"
                value={inputValue?.compnayName}
                onChange={(e) => {
                  handleOnChange(e);
                }}
              />
            </div>

            <div className="input">
              <input
                type="text"
                placeholder="VORNAME"
                id="fname"
                name="fname"
                value={inputValue?.fname}
                onChange={(e) => {
                  handleOnChange(e);
                }}
              />
              <span style={{ color: "#d92c2c" }}>{errors?.fname}</span>
            </div>

            <div className="input">
              <input
                type="text"
                placeholder="NACHNAME"
                id="lname"
                name="lname"
                value={inputValue?.lname}
                onChange={(e) => {
                  handleOnChange(e);
                }}
              />
              <span style={{ color: "#d92c2c" }}>{errors?.lname}</span>
            </div>

            <div className="input">
              <input
                type="text"
                placeholder="E-MAIL"
                id="email"
                name="email"
                value={inputValue?.email}
                onChange={(e) => {
                  handleOnChange(e);
                }}
              />

              <span style={{ color: "#d92c2c" }}>{errors?.email}</span>
            </div>

            <div className="input">
              <input
                type="text"
                placeholder="TELEFON"
                id="phone"
                name="phone"
                onKeyPress={bindInput}
                value={inputValue?.phone}
                onChange={(e) => {
                  handleOnChange(e);
                }}
              />
              <span style={{ color: "#d92c2c" }}>{errors?.phone}</span>
            </div> */}

            <div class="floating-form">
              <div class="floating-label">
                <input
                  class="floating-input"
                  type="text"
                  placeholder=" "
                  id="fname"
                  name="fname"
                />
                <span class="highlight"></span>
                <label>FIRMA (optional)</label>
              </div>
              <div class="floating-label">
                <input
                  class="floating-input"
                  type="text"
                  placeholder=" "
                  id="fname"
                  name="fname"
                />
                <span class="highlight"></span>
                <label>VORNAME</label>
              </div>
              <div class="floating-label">
                <input
                  class="floating-input"
                  type="text"
                  placeholder=" "
                  id="fname"
                  name="fname"
                />
                <span class="highlight"></span>
                <label>NACHNAME</label>
              </div>
              <div class="floating-label">
                <input
                  class="floating-input"
                  type="text"
                  placeholder=" "
                  id="fname"
                  name="fname"
                />
                <span class="highlight"></span>
                <label>E-MAIL</label>
              </div>
              <div class="floating-label">
                <input
                  class="floating-input"
                  type="text"
                  placeholder=" "
                  id="fname"
                  name="fname"
                />
                <span class="highlight"></span>
                <label>TELEFON</label>
              </div>
            </div>
          </div>
          <div className="grid-items">
            <div className="radio-text-alignment">
              <div>
                <input
                  type="checkbox"
                  className="input-radio"
                  name="Financial Guidance"
                  value="Financial Guidance"
                  id="test1"
                  onChange={(e) => {
                    handleOnChangeForRadio(e);
                  }}
                />
                <label for="test1">
                  {" "}
                  <span>Financial Guidance</span>
                </label>
              </div>
            </div>
            <div className="radio-text-alignment">
              <div>
                <input
                  type="checkbox"
                  name="Personalbveratung"
                  className="input-radio"
                  value="Personalbveratung"
                  id="test2"
                  onChange={(e) => {
                    handleOnChangeForRadio(e);
                  }}
                />
                <label for="test2">
                  {" "}
                  <span>Personalberatung</span>
                </label>
              </div>
            </div>
            <div className="radio-text-alignment">
              <div>
                <input
                  type="checkbox"
                  name="Karrierenstitut"
                  className="input-radio"
                  value="Karrierenstitut"
                  id="test3"
                  onChange={(e) => {
                    handleOnChangeForRadio(e);
                  }}
                />
                <label for="test3">
                  {" "}
                  <span>Karrierenstitut</span>
                </label>
              </div>
            </div>
            <div className="radio-text-alignment">
              <div>
                <input
                  type="checkbox"
                  name="Unternehmensberatung"
                  className="input-radio"
                  value="Unternehmensberatung"
                  id="test4"
                  onChange={(e) => {
                    handleOnChangeForRadio(e);
                  }}
                />
                <label for="test4">
                  <span>Unternehmensberatung</span>
                </label>
              </div>
            </div>
            <div className="radio-text-alignment">
              <div>
                <input
                  type="checkbox"
                  name="Energy & Finance"
                  className="input-radio"
                  value="Energy & Finance"
                  id="test5"
                  onChange={(e) => {
                    handleOnChangeForRadio(e);
                  }}
                />
                <label for="test5">
                  <span>Energy & Finance</span>
                </label>
              </div>
            </div>
            <div className="text-area">
              <textarea
                placeholder="Dein Anliegen"
                id="description"
                name="description"
                value={inputValue?.description}
                onChange={(e) => {
                  handleOnChange(e);
                }}></textarea>
              <span style={{ color: "#d92c2c" }}>{errors?.description}</span>
            </div>

            <div className="submit-button">
              <span onClick={(e) => submitContactForm()}>ABSENDEN</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
