import React, { useEffect, useState, useCallback } from "react";
// import "./karriereinstitut.scss";
import { NavLink, useHistory } from "react-router-dom";

export default function DasAngebot2() {
  const history = useHistory();
  return (
    <>
      <div className="dasange-bot-all-content-alignment" id="Das-Angebot">
        <div className="all-text-alignment">
          <div className="text-style">
            <h5>
              <span
                style={{
                  paddingRight: "10px",
                  position: "relative",
                  top: "-6px",
                }}
              >
                .
              </span>{" "}
              Bewerbungstraining für Young Professionals
            </h5>
            <p>
              Mit persönlichen Seminaren und Coachings bereiten wir dich auf
              bevorstehende Bewerbungssituationen vor. Dabei werden nicht nur
              klassische Situationen wie das Vorstellungsgespräch trainiert,
              sondern auch Assessment Center und Gehaltsgespräche. Unsere
              Coaches sind Experten im Bereich Recruiting und können daher
              besonders individuell und bedarfsgerecht beraten.
            </p>
            <div className="button-new-mobile-center-alignment">
              <button
                onClick={() => {
                  history.push("/steper-view");
                }}
              >
                Jetzt informieren und Potential entfalten
              </button>
            </div>
          </div>
          <div className="text-style">
            <h5>
              {" "}
              <span
                style={{
                  paddingRight: "10px",
                  position: "relative",
                  top: "-6px",
                }}
              >
                .
              </span>
              Jobportal für deine Traumstelle
            </h5>
            <p>
              Auf unserem kostenfreien Jobportal finden Studierende, Absolventen
              und Fachkräfte offene Stellen bei mehr als 850 Arbeitgebern. Finde
              schnell und einfach den Job, der zu dir und deinen Fähigkeiten
              passt.
            </p>
            <div className="button-new-mobile-center-alignment">
              <button
                onClick={() => {
                  history.push("/steper-view");
                }}
              >
                Jetzt informieren und Potential entfalten
              </button>
            </div>
          </div>
          <div className="text-style">
            <h5>
              {" "}
              <span
                style={{
                  paddingRight: "10px",
                  position: "relative",
                  top: "-6px",
                }}
              >
                .
              </span>
              Fit für den Arbeitsmarkt: Mit unserem Karrierecoaching
            </h5>
            <p>
              Das Karrierecoaching macht dich fit für den Arbeitsmarkt. Vom
              Anschreiben und Lebenslauf bis hin zum Vorstellungsgespräch - hier
              wird der gesamte Bewerbungsprozess trainiert. Im daran
              anknüpfenden Gehaltsverhandlungsseminar lernst du, dich in
              Verhandlungssituationen zu behaupten. Mithilfe unseres Seminars
              hast du die Chance auf bis zu 15% mehr Gehalt im neuen Job.
            </p>
            <div className="button-new-mobile-center-alignment">
              <button
                onClick={() => {
                  history.push("/steper-view");
                }}
              >
                Jetzt informieren und Potential entfalten
              </button>
            </div>
          </div>
          <div className="text-style">
            <h5>
              {" "}
              <span
                style={{
                  paddingRight: "10px",
                  position: "relative",
                  top: "-6px",
                }}
              >
                .
              </span>
              Selbstpräsentation auf Social Media
            </h5>
            <p>
              Die sozialen Medien sind der perfekte Ort, um dich und deine
              Fähigkeiten herauszustellen. Wir checken deine Präsenzen auf XING
              und Linkedin und zeigen dir, wie du dich für Headhunter und Co.
              noch attraktiver in Szene setzt. Außerdem prüfen wir deine Social
              Media Profile auf Facebook und Instagram - denn immer mehr
              Arbeitgeber checken auch die privaten Profile ihrer Bewerber.
            </p>
            <div className="button-new-mobile-center-alignment">
              <button
                onClick={() => {
                  history.push("/steper-view");
                }}
              >
                Jetzt informieren und Potential entfalten
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
