import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";

import "./home.scss";
import FeFinanceLogo from "../../../assets/logo/fefinance1.svg";
import FeWerder from "../../../assets/logo/FE-F.svg";

import RightLgArrow from "../../../assets/icons/lg-right.svg";
import LgLEftArrow from "../../../assets/icons/lg-left.svg";
import MdRight from "../../../assets/icons/md-right-icon.svg";
import WpLogo from "../../../assets/icons/wp-logo.png";
import ItnorLogo from "../../../assets/images/itnor.svg";
import karrierLogo from "../../../assets/icons/karrier-logo.png";
import LineType from "../../../assets/icons/arrow-with-icon.svg";
import FirstLogo from "../../../assets/images/new-logo-one.png";
import HeaderArrow from "../../../assets/icons/header-arrow.svg";
import HeroTextSection from "./heroTextSection";
import Loader from "../../loader";
import { NavLink } from "react-router-dom";
import MobileShow from "../../mobileShow";
import MobileViewHeader from "../../MobileViewHeader";

export default function Home() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const [activeIndex, setActiveIndex] = useState(1);
  const next = () => {
    setActiveIndex((count) => count + 1);
  };
  const prev = () => {
    setActiveIndex((count) => count - 1);
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      let d = document.getElementById("home-contnet");
      d.classList.remove("home-all-content-none");
    }, 2000);
    return () => {
      clearTimeout();
    };
  }, []);

  return (
    <>
      <Loader />
      <motion.div
        initial={{ x: "100%" }}
        animate={{ x: "0" }}
        transition={{
          duration: 1,
          delay: 2,
        }}
      >
        <div
          id="home-contnet"
          className="home-all-contnet-alignment home-all-content-none"
        >
          <div className="grid">
            <div className="grid-items">
              <div className="sub-grid">
                <div className="sub-grid-items mobile-view-fe-home-page-section-remove">
                  <div className="first-content-alignment">
                    <div className="new-alignment-m">
                      <div className="logo">
                        <NavLink to="/">
                        <a>
            <div className="two-contnet-alignment">
              <img
              className="image-first"
              src={FeFinanceLogo} alt="FeFinanceLogo"
              />
              <img
              className="sec-first"
                src={FeWerder}
                alt=""
              />
              </div>
            </a>
                        </NavLink>
                      </div>
                      <div className="white-button">
                        <NavLink to="/steper-view">
                          <button>JETZT BERATEN LASSEN</button>
                        </NavLink>
                      </div>
                    </div>
                    <div className="menu-alignment">
                      <ul>
                        <NavLink to="/financial-guidance">
                          <li>
                            <img
                              className={
                                window.location.pathname ===
                                "/financial-guidance"
                                  ? "header-arrow-show"
                                  : "header-arrow-hidden"
                              }
                              src={HeaderArrow}
                              alt="HeaderArrow"
                            />
                            FINANCIAL GUIDANCE
                          </li>
                        </NavLink>
                        <NavLink to="/karriereinstitut">
                          <li>
                            <img
                              className={
                                window.location.pathname === "/karriereinstitut"
                                  ? "header-arrow-show"
                                  : "header-arrow-hidden"
                              }
                              src={HeaderArrow}
                              alt="HeaderArrow"
                            />
                            KARRIEREINSTITUT
                          </li>
                        </NavLink>
                        <NavLink to="/personalberatung">
                          <li>
                            <img
                              className={
                                window.location.pathname === "/personalberatung"
                                  ? "header-arrow-show"
                                  : "header-arrow-hidden"
                              }
                              src={HeaderArrow}
                              alt="HeaderArrow"
                            />
                            PERSONALBERATUNG
                          </li>
                        </NavLink>
                        <NavLink to="/unternehmensberatung">
                          <li>
                            <img
                              className={
                                window.location.pathname ===
                                "/unternehmensberatung"
                                  ? "header-arrow-show"
                                  : "header-arrow-hidden"
                              }
                              src={HeaderArrow}
                              alt="HeaderArrow"
                            />
                            UNTERNEHMENSBERATUNG
                          </li>
                        </NavLink>
                        <a href="http://fe-finance.solar-pvcheck.de/" target="_blank">
                          <li>
                          <img
                              className="header-arrow-hidden"
                              src={HeaderArrow}
                              alt="HeaderArrow"
                            />
                            PV CHECK</li>
                        </a>
                      </ul>
                      <ul>
                        <NavLink to="/kontakt">
                          <li>
                            <img
                              className={
                                window.location.pathname === "/kontakt"
                                  ? "header-arrow-show"
                                  : "header-arrow-hidden"
                              }
                              src={HeaderArrow}
                              alt="HeaderArrow"
                            />
                            KONTAKT
                          </li>
                        </NavLink>
                        <NavLink to="/karriere">
                          <li>
                            <img
                              className={
                                window.location.pathname === "/karriere"
                                  ? "header-arrow-show"
                                  : "header-arrow-hidden"
                              }
                              src={HeaderArrow}
                              alt="HeaderArrow"
                            />
                            KARRIERE
                          </li>
                        </NavLink>
                        <NavLink to="/uber-uns">
                          <li>
                            <img
                              className={
                                window.location.pathname === "/uber-uns"
                                  ? "header-arrow-show"
                                  : "header-arrow-hidden"
                              }
                              src={HeaderArrow}
                              alt="HeaderArrow"
                            />
                            ÜBER FE FINANCE
                          </li>
                        </NavLink>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="sub-grid-items">
                  {activeIndex === 1 && (
                    <div className="cus-slider-content-sapce-alignment mobile-block-first-box-align">
                      <div>
                        <div>
                          <h2 className="animate__animated animate__slideInLeft">
                            Unsere Netzwerk {"-"} Partner
                          </h2>
                        </div>

                        <div className="arrow-content-alignment" onClick={next}>
                          <div className="animate__animated animate__slideInUp">
                            <img src={RightLgArrow} alt="RightLgArrow" />
                          </div>
                        </div>
                        <div></div>
                        <div className="first-logo-style">
                          <p>verbraucherschutzkonforme Beratung nach</p>
                          <img
                            className="animate__animated animate__slideInDown"
                            src={FirstLogo}
                            alt="FirstLogo"
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {/* 2 */}

                  {activeIndex === 2 && (
                    <div className="mobile-height-slider">
                      <div onClick={prev} className="lg-slider-left-arrow">
                        <img src={LgLEftArrow} alt="LgLEftArrow" />
                      </div>
                      <div className="cus-slider-content-sapce-alignment">
                        {/* new prev */}
                        <div className="logo-text-grid">
                          <div className="logo-text-grid-items">
                            <div className="grid-alignment-logo-center-alignment">
                              <NavLink to="/karriereinstitut">
                                <img
                                  className="animate__animated animate__slideInUp"
                                  src={karrierLogo}
                                  alt="karrierLogo"
                                />
                              </NavLink>
                            </div>
                          </div>
                          <div className="logo-text-grid-items">
                            <div className="new-text-new-icon-grid-alignment">
                              <div className="new-icon-text-grid ">
                                <div className="new-icon-text-grid-items">
                                  <img className="mobile-view-icon-alignment" src={LineType} alt="LineType" />
                                </div>
                                <div className="new-icon-text-grid-items">
                                  <p className="animate__animated animate__slideInUp">
                                  Entdecke deine Talente
                                  </p>
                                </div>
                              </div>
                              <div className="new-icon-text-grid animate__animated animate__slideInUp">
                                <div className="new-icon-text-grid-items">
                                  <img className="mobile-view-icon-alignment" src={LineType} alt="LineType" />
                                </div>
                                <div className="new-icon-text-grid-items">
                                  <p>Verbessere deine Skills</p>
                                </div>
                              </div>
                              <div className="new-icon-text-grid animate__animated animate__slideInUp">
                                <div className="new-icon-text-grid-items">
                                  <img className="mobile-view-icon-alignment" src={LineType} alt="LineType" />
                                </div>
                                <div className="new-icon-text-grid-items">
                                  <p>Mit FE Finance</p>
                                </div>
                              </div>
                              <div className="new-icon-text-grid animate__animated animate__slideInUp">
                                <div className="new-icon-text-grid-items">
                                  {/* <img className="mobile-view-icon-alignment" src={LineType} alt="LineType" /> */}
                                </div>
                                <div className="new-icon-text-grid-items">
                                  <p>
                                    <span>10-15% </span>  mehr Gehalt
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* new next */}
                        <div onClick={next} className="slide-right-icon">
                          <img src={MdRight} alt="MdRight" />
                        </div>
                      </div>
                    </div>
                  )}

                  {/* 3 */}

                  {activeIndex === 3 && (
                    <div className="mobile-height-slider">
                      <div onClick={prev} className="lg-slider-left-arrow">
                        <img src={LgLEftArrow} alt="LgLEftArrow" />
                      </div>
                      <div className="cus-slider-content-sapce-alignment">
                        <div className="logo-text-grid">
                          <div className="logo-text-grid-items">
                            <div className="grid-alignment-logo-center-alignment">
                              <NavLink to="/personalberatung">
                                <img
                                  className="animate__animated animate__slideInUp"
                                  src={WpLogo}
                                  alt="WpLogo"
                                />
                              </NavLink>
                            </div>
                          </div>
                          <div className="logo-text-grid-items">
                            <div className="new-text-new-icon-grid-alignment">
                              <div className="new-icon-text-grid animate__animated animate__slideInUp">
                                <div className="new-icon-text-grid-items">
                                  <img className="mobile-view-icon-alignment" src={LineType} alt="LineType" />
                                </div>
                                <div className="new-icon-text-grid-items">
                                  <p>
                                  Nutze unser Unternehmensnetzwerk um dein Potenzial zu entfalten
                                   
                                  </p>
                                </div>
                              </div>
                              <div className="new-icon-text-grid animate__animated animate__slideInUp">
                                <div className="new-icon-text-grid-items">
                                  <img className="mobile-view-icon-alignment" src={LineType} alt="LineType" />
                                </div>
                                <div className="new-icon-text-grid-items">
                                  <p>
                                  Erhalte Zugriff auf über <span>850 </span>{" "}
                                  Arbeitgeber
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div onClick={next} className="slide-right-icon">
                          <img src={MdRight} alt="MdRight" />
                        </div>
                      </div>
                    </div>
                  )}
                  {/* 4 */}
                  {activeIndex === 4 && (
                    <div className="mobile-height-slider">
                      <div onClick={prev} className="lg-slider-left-arrow">
                        <img src={LgLEftArrow} alt="LgLEftArrow" />
                      </div>
                      <div className="cus-slider-content-sapce-alignment">
                        <div className="logo-text-grid">
                          <div className="logo-text-grid-items">
                            <div className="grid-alignment-logo-center-alignment">
                              <NavLink to="/unternehmensberatung">
                                <img
                                  className="animate__animated animate__slideInUp inter-logo-image-style-alignment"
                                  src={ItnorLogo}
                                  alt="ItnorLogo"
                                />
                              </NavLink>
                            </div>
                          </div>
                          <div className="logo-text-grid-items">
                            <div className="new-text-new-icon-grid-alignment">
                              <div className="new-icon-text-grid animate__animated animate__slideInUp">
                                <div className="new-icon-text-grid-items">
                                  <img src={LineType} alt="LineType" />
                                </div>
                                <div className="new-icon-text-grid-items">
                                  <p>
                                  Staatliche Förderungen nutzen
                                  </p>
                                </div>
                              </div>


                              <div className="new-icon-text-grid animate__animated animate__slideInUp">
                                <div className="new-icon-text-grid-items">
                                  <img src={LineType} alt="LineType" />
                                </div>
                                <div className="new-icon-text-grid-items">
                                  <p>
                                  Unabhängige Lösungen ohne Bindung an konkrete Gesellschaften
                                  </p>
                                </div>
                              </div>
                              <div className="new-icon-text-grid animate__animated animate__slideInUp">
                                <div className="new-icon-text-grid-items">
                                  <img src={LineType} alt="LineType" />
                                </div>
                                <div className="new-icon-text-grid-items">
                                  <p>
                                  Maßgeschneiderte Sparlösungen für deinen Lifestyle
                                  </p>
                                </div>
                              </div>
                              <div className="new-icon-text-grid animate__animated animate__slideInUp">
                                <div className="new-icon-text-grid-items">
                                  <img src={LineType} alt="LineType" />
                                </div>
                                <div className="new-icon-text-grid-items">
                                  <p>
                                  Deutschlands einzige Verbraucherschutzkonforme Beratung
                                  </p>
                                </div>
                              </div>

                              
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* 4 */}
                </div>
              </div>
            </div>
            <div className="grid-items">
              <HeroTextSection />
            </div>
          </div>
          <div className="mobile-view-footer-show-alignment">
            <NavLink to="/impressum">Impressum</NavLink>
            <a style={{ padding: "0 3px" }}> | </a>
            <NavLink to="/datenschutzerklarung">Datenschutz</NavLink>

            <p>Copyright © 2022 FE FINANCE. Alle Rechte vorbehalten.</p>
          </div>
        </div>
      </motion.div>
    </>
  );
}
