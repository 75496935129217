import React from "react";
import "./unserCoaching.scss";
import MapImage from "../../../../assets/images/new-map.png";
import FooterLogo from "../../../../assets/logo/bolg-logo.png";
import SecIcon from "../../../../assets/icons/se-logo.svg";

export default function UnserCoaching() {
  return (
    <>
      <div className="unser-coahing-all-content-alignment">
        <div className="page-title" id="UnserCoaching">
          <h4>Unser Coaching-Angebot</h4>
          <p>
            Vom Vorstellungsgespräch über die Vorbereitung auf Assessment Center
            bis hin zur Gehaltsverhandlung - es gibt viele Hürden, die Bewerber
            nehmen müssen.
          </p>
          <p>
            Mit unserer Hilfe nimmst du diese Hürden souverän. Wir bereiten dich
            auf alle Bereiche des Berufslebens vor und erhöhen so deine
            Karrierechancen.
          </p>
          <h3> Unsere Leistungen im Überblick</h3>
          <ul>
            <li>Lebenslauf-Optimierung</li>
            <li> E-Learning Plattform</li>
            <li> Persönliches Coaching</li>
            <li>und mehr</li>
          </ul>
        </div>
        <div id="Fakten" style={{ marginBottom: "10%" }}>
          <div className="map-grid">
            <div className="map-grid-items">
              <div className="fakten-content-style">
                <h5>Fakten</h5>
              </div>
              <ul>
                <li>+ 30 Standorte</li>
                <li>über 10700 Mitglieder</li>
                <li>mehr als 3000</li>
              </ul>
              <h6 style={{ paddingLeft: "20px" }}>
                Seminarteilnehmer jährlich
              </h6>
              {/* <div className="all-iamge-alignment">
                <div>
                  <img src={FooterLogo} alt="FooterLogo" />
                </div>
                <div>
                  <img src={SecIcon} alt="SecIcon" />
                </div>
              </div> */}
            </div>
            <div className="map-grid-items">
              <div className="map-iamge-style">
                <img src={MapImage} alt="MapImage" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
