import React, { useEffect } from "react";
import "./uberUns.scss";
import UberUnsSteper from "../../steper/uberUnsSteper";
import Career1 from "../../../assets/images/12345.png";
import FeFinance from "./feFinance";
import { NavLink } from "react-router-dom";
import MobileViewHeader from "../../MobileViewHeader";
export default function UberUns() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <MobileViewHeader />
      <UberUnsSteper />
      <div className="uber-uns-all-content-alignment">
        <div className="white-button">
          <NavLink to="/kontakt">
            <button>KONTAKT AUFNEHMEN</button>
          </NavLink>
        </div>
        <div className="container-xl">
          <div className="grid">
            <div className="grid-items" id="uber">
              <h1>Über uns</h1>
              <br />
              <br />
              <p>
                Eigene Ziele erreichen und finanziell sorgenfrei leben - das ist
                es, was wir uns für unsere Mandanten wünschen. Und für dich!
              </p>
              <p className="fist-text-image-related-alignment">
                Auf unterschiedlichen Ebenen arbeiten wir gemeinsam daran, deine
                Persönlichkeit zu stärken und deine Arbeitssituation so zu
                optimieren, dass du langfristig zu finanzieller Sicherheit
                gelangst - aber auch beruflich erfüllt bist.
              </p>
              <p className="sec-text-image-related-alignment sec-text-image-related-alignment-mobile">
                Darüber hinaus beraten wir Unternehmenskunden in
                unterschiedlichen Situationen: Angefangen bei der Finanz- und
                Risikoanalyse über die Unternehmenssicherheit bis hin zur
                Findung, Entwicklung und Bindung der Belegschaft. Für
                langfristigen Erfolg.
              </p>
              {/* <p className="text-width-size">
                Du hast Lust, Teil unseres Teams zu werden? Dann bewirb dich
                jetzt bei uns! Wir haben ein tolles Arbeitsklima, flexible
                Arbeitszeiten und bieten dir die Chance, dich
                weiterzuentwickeln. Und das Ganze zu einem super Gehalt!
              </p>
              <p className="text-width-size text-width-size-mobile">
                Du hast Lust, Teil unseres Teams zu werden? Dann bewirb dich
                jetzt bei uns! Wir haben ein tolles Arbeitsklima, flexible
                Arbeitszeiten und bieten dir die Chance, dich
                weiterzuentwickeln. Und das Ganze zu einem super Gehalt!
              </p> */}
              <div className="write-text">
                <h6> -Patrick Germer, </h6>
                <span>Geschäftsführer</span>
              </div>
              <div className="tab-view-image-show">
                <img src={Career1} alt="Career1" />
              </div>
            </div>
          </div>
        </div>
        <div className="container-size-change-fe-finance">
          <div className="container-md">
            <FeFinance />
          </div>
        </div>
      </div>
    </>
  );
}
