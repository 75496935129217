import React, { useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import FinancialGuidance from "./routes/financialGuidance";
import Home from "./routes/home";
import Header from "../components/header/index";
import Footer from "./footer";
import Karriereinstitut from "./routes/karriereinstitut";
import Personalberatung from "./routes/personalberatung";
import Unternehmensberatung from "./routes/unternehmensberatung";
import Kontakt from "./routes/kontakt";
import Karriere from "./routes/karriere";
import KarriereInformation from "./routes/karriereInformation";
import Apply from "./routes/apply";
import UberUns from "./routes/uberUns";
import Steper from "./routes/steperView/index";
import TerminVereinbaren from "./routes/terminVereinbaren";
import Datenschutzerklarung from "./routes/datenschutzerklärung";
import Impressum from "./routes/impressum";
import CookieForm from "./Cookie/CookieForm";

const DefaultLayout = ({ children, match }) => {
  const [show, setShow] = useState(false);
  const [cancel, setCancel] = useState(false);
  return (
    <>
      <CookieForm
        show={show}
        setShow={setShow}
        cancel={cancel}
        setCancel={setCancel}
      />
      {children}
    </>
  );
};

const ChildLayout = ({ children, match }) => (
  <>
    <div className="full-page-background">
      <Header />
      {children}
      <Footer />
    </div>
  </>
);

function RouteWrapper({ component: Component, layout: Layout, auth, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}
export default function Routes() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <RouteWrapper
            exact={true}
            path="/"
            component={Home}
            layout={DefaultLayout}
          />
          <RouteWrapper
            exact={true}
            path="/steper-view"
            component={Steper}
            layout={DefaultLayout}
          />
          <RouteWrapper
            exact={true}
            path="/termin-vereinbaren"
            component={TerminVereinbaren}
            layout={DefaultLayout}
          />
          <RouteWrapper
            exact={true}
            path="/financial-guidance"
            component={FinancialGuidance}
            layout={ChildLayout}
          />
          <RouteWrapper
            exact={true}
            path="/karriereinstitut"
            component={Karriereinstitut}
            layout={ChildLayout}
          />
          <RouteWrapper
            exact={true}
            path="/personalberatung"
            component={Personalberatung}
            layout={ChildLayout}
          />
          <RouteWrapper
            exact={true}
            path="/unternehmensberatung"
            component={Unternehmensberatung}
            layout={ChildLayout}
          />
          <RouteWrapper
            exact={true}
            path="/kontakt"
            component={Kontakt}
            layout={ChildLayout}
          />
          <RouteWrapper
            exact={true}
            path="/datenschutzerklarung"
            component={Datenschutzerklarung}
            layout={ChildLayout}
          />
          <RouteWrapper
            exact={true}
            path="/impressum"
            component={Impressum}
            layout={ChildLayout}
          />
          <RouteWrapper
            exact={true}
            path="/karriere"
            component={Karriere}
            layout={ChildLayout}
          />
          <RouteWrapper
            exact={true}
            path="/karriere-information/:id"
            component={KarriereInformation}
            layout={ChildLayout}
          />
          <RouteWrapper
            exact={true}
            path="/apply"
            component={Apply}
            layout={ChildLayout}
          />
          <RouteWrapper
            exact={true}
            path="/uber-uns"
            component={UberUns}
            layout={ChildLayout}
          />
        </Switch>
      </BrowserRouter>
    </>
  );
}
