import React from "react";
import "./steper.scss";

export default function karriereinstitutSteper() {
  return (
    <>
      <div className="all-steper-top-rigth-content-alignment">
        <div className="all-steper-grid-alignment">
          <div className="steper-grid">
            <div className="steper-grid-items">
              <a href="#karriereinstitut">
                <span className="steper-all-content-new-alignment">
                  Karriereinstitut
                </span>
              </a>
            </div>
            <div className="steper-grid-items">
              <div className="steper-round-design">
                <div></div>
              </div>
            </div>
          </div>
          <div className="steper-grid">
            <div className="steper-grid-items">
              <a href="#Marktwert-steigern">
                <span className="steper-all-content-new-alignment">
                  Marktwert steigern
                </span>
              </a>
            </div>
            <div className="steper-grid-items">
              <div className="steper-round-design">
                <div></div>
              </div>
            </div>
          </div>
          <div className="steper-grid">
            <div className="steper-grid-items">
              <a href="#UnserCoaching">
                <span>Unser Coaching-Angebot</span>
              </a>
            </div>
            <div className="steper-grid-items">
              <div className="steper-round-design">
                <div></div>
              </div>
            </div>
          </div>
          <div className="steper-grid">
            <div className="steper-grid-items">
              <a href="#Fakten">
                <span className="steper-all-content-new-alignment">Fakten</span>
              </a>
            </div>
            <div className="steper-grid-items">
              <div className="steper-round-design">
                <div></div>
              </div>
            </div>
          </div>
          <div className="steper-grid">
            <div className="steper-grid-items">
              <span>Karriere-Coaching-vereinbaren</span>
            </div>
            <div className="steper-grid-items">
              <div className="steper-round-design">
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
