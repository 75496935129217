import React from "react";

export default function UberUnsSteper() {
  return (
    <div>
      <div className="all-steper-top-rigth-content-alignment">
        <div className="all-steper-grid-alignment">
          <div className="steper-grid">
            <div className="steper-grid-items">
              <a href="#uber">
                <span className="steper-all-content-new-alignment">
                  Über uns
                </span>
              </a>
            </div>
            <div className="steper-grid-items">
              <div className="steper-round-design">
                <div></div>
              </div>
            </div>
          </div>
          <div className="steper-grid">
            <div className="steper-grid-items">
              <a href="#team">
                <span className="steper-all-content-new-alignment">
                  Das Team
                </span>
              </a>
            </div>
            <div className="steper-grid-items">
              <div className="steper-round-design">
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
