import React from "react";
import "./maktwertSteigern.scss";
export default function MaktwertSteigern() {
  return (
    <div>
      <div
        className="marktwert-seteigern-content-alignment"
        id="Marktwert-steigern"
      >
        <div className="page-title">
          <h3>Steigere deinen Marktwert</h3>
          <p>
            Jeder Mensch ist anders. Und jeder Mensch hat besondere Fähigkeiten
            und Kompetenzen. Diese richtig zu nutzen, ist der Garant für
            langfristigen Erfolg.
          </p>
          <span>
            Ob Student, Berufseinsteiger oder Young Professional - wir sind der
            Meinung, dass jeder seine berufliche Perspektive selbst beeinflussen
            kann. Durch Unterstreichen der eigenen Persönlichkeit und den Ausbau
            der Kompetenzen.
          </span>
          <span>
            Wir ermitteln, welcher Karrieretyp du bist. Mit einer individuellen
            Persönlichkeitsanalyse. Auf Basis der Ergebnisse folgt dann die
            Entwicklung der eigenen Kompetenzen.
          </span>
          <span>
            Jährlich verhelfen wir rund 3.000 Menschen mit unserem
            Karriereinstitut dabei, den Boost in ihrer Karriere zu zünden. Jetzt
            bist du dran!
          </span>
        </div>
      </div>
    </div>
  );
}
