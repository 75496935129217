import React, { useEffect } from "react";
import FinancialGuidanceSteper from "../../steper/karriereinstitutSteper";
import KarriereContent from "./karriereContent";
import { NavLink } from "react-router-dom";

import "./karriereinstitut.scss";
import MaktwertSteigern from "./maktwertSteigern";
import UnserCoaching from "./unserCoaching";
import UnsereNetzwerkpartner from "./unsereNetzwerkpartner";
import MobileViewHeader from "../../MobileViewHeader";
export default function Karriereinstitut() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <MobileViewHeader />
      <FinancialGuidanceSteper />
      <div className="karriereinstitut-all-content-alignment">
        <div className="white-button">
          <NavLink to="/steper-view">
            <button>JETZT BERATEN LASSEN</button>
          </NavLink>
        </div>
        <div className="container-lg">
          <div className="hero-text-style" id="karriereinstitut">
            <h1>Das Karriereinstitut</h1>
            <p>
              Das eigene Potenzial erkennen, fördern und nutzen. Das ist das
              Geheimnis für eine erfolgreiche berufliche Zukunft. Wir helfen dir
              dabei, dieses Geheimnis zu leben: mit unserem Karriereinstitut.
            </p>
            <span>
              Im Gespräch mit dir erkennen wir deine Stärken. Auf Basis deiner
              Skills bieten wir persönliche Coachings - die deine Kompetenzen
              erweitern und dich als Persönlichkeit wachsen lassen. Das
              Ergebnis: Ein höherer Marktwert.
            </span>
          </div>
          <KarriereContent />
          <MaktwertSteigern />
          <UnserCoaching />
          <UnsereNetzwerkpartner />
        </div>
      </div>
    </>
  );
}
