import React from "react";
import { NavLink } from "react-router-dom";
import "./heroTextSection.scss";
export default function HeroTextSection() {
  return (
    <div>
      <div className="hero-text-section-contnet-alignment">
        <div className="white-button">
          <NavLink to="/kontakt">
            <button>KONTAKT AUFNEHMEN</button>
          </NavLink>
        </div>
        <div className="home-text-style">
          <h1 className="animate__slideInUp animate__animated">
            FE FINANCE GMBH
          </h1>
          <p className="animate__slideInUp animate__animated">
            Finanziell sicher in die Zukunft starten Wir sind für dich da - die
            Profis der <br />FE Finance GmbH
          </p>
          <div className="white-button ">
            <NavLink to="/termin-vereinbaren">
              <button>Kostenfreien Beratungstermin vereinbaren</button>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="last-items-home-section-footer-alignment">
        <div>
          <NavLink to="/impressum">Impressum</NavLink>
          <a style={{ padding: "0 3px" }}> | </a>
          <NavLink to="/datenschutzerklarung">Datenschutz</NavLink>
        </div>
        <div>
          <p>Copyright © 2022 FE FINANCE. Alle Rechte vorbehalten.</p>
        </div>
      </div>
    </div>
  );
}
