import React, { useEffect } from "react";
import PersonalberatungSteper from "../../steper/personalberatungSteper";
import DasAngebot from "./dasAngebot";
import KarriereMachen from "./karriereMachen";
import "./personalberatung.scss";
import { NavLink } from "react-router-dom";
import MobileViewHeader from "../../MobileViewHeader";

export default function Personalberatung() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <MobileViewHeader />
      <PersonalberatungSteper />
      <div className="personal-bertung-all-content-alignment">
        <div className="white-button">
          <NavLink to="/steper-view">
            <button>JETZT BERATEN LASSEN</button>
          </NavLink>
        </div>
        <div className="container-lg">
          <div className="hero-text-style" id="Personal-beratung">
            <h1>Personalberatung</h1>
            <p>
              „Wenn man morgens glücklich zur Arbeit fährt, dann hat man den
              richtigen Job gewählt.“ Wir helfen dir dabei, dass es dir so geht.
              Auf unserem eigenen Jobportal vermitteln wir Stellen, die perfekt
              zu dir passen. Etwa für Studierende, Young Professionals und
              erfahrene Fachkräfte.
            </p>
          </div>
          <KarriereMachen />
          <DasAngebot />
        </div>
      </div>
    </div>
  );
}
