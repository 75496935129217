import React from "react";
import "./secSteper.scss";
import SteperRightIcon from "../../../../assets/icons/steper-right.svg";
import TrueIcon from "../../../../assets/icons/true-icon.svg";
import RightIcon from "../../../../assets/icons/black-right.svg";
import { useHistory } from "react-router-dom";


export default function SecSteper(props) {
  let history = useHistory();

  const { setKey,setTabview} = props
  const redireForthPage = () =>{
    props.setTabview("four");
  }

 return (
    <div>
      <div className="first-steper-all-content-alignment">
        <div className="text-grid">
          <div className="text-grid-items">
            <div className="text-image-alignment">
              <span>2</span>
              <img src={SteperRightIcon} alt="SteperRightIcon" />
            </div>
          </div>
          <div className="text-grid-items">
            <div className="sub-text-style">
              <h2>Wurdest du schon beraten?</h2>
              <p>
                Wir helfen Spitzensteuerzahlern Ihre staatlichen Abgaben in ein
                stabiles und nachhaltiges Investment zu verwandeln.
              </p>
            </div>
            <div className="auto-fill-all-button">
              <button onClick={() => {
                props.setTabview("three")
                setKey(["JA"])
              }}>
                <span>JA</span>
                <img src={TrueIcon} alt="TrueIcon" />
              </button>
              <button onClick={() => {
                redireForthPage()
                // props.setTabview("three")
                setKey(["NEIN"])
              }}>
                <span>NEIN</span>
                <img src={RightIcon} alt="RightIcon" />
              </button>
              <button onClick={() => {
                props.setTabview("three")
                setKey(["WEIß ICH NCIHT"])
              }}>WEIß ICH NCIHT</button>
            </div>
          </div>
        </div>
        <div className="button-left-alignment">
          <button  onClick={() => setTabview("first")}>zurück</button>
        </div>
      </div>
    </div>
  );
}
