import React from "react";
import "./kontaktInformation.scss";
import LocationImage from "../../../../assets/images/location.png";
import FooterLogo from "../../../../assets/logo/bolg-logo1.svg";

export default function KontaktInformation() {
  return (
    <div>
      <div
        className="kontakt-information-all-content-page-alignment"
        id="Kontaktdaten">
        <div className="grid">
          <div className="grid-items">
            <div className="image-grid">
              <div className="image-grid-items image-grid-items-change">
                <img src={FooterLogo} alt="FooterLogo" />
              </div>
            </div>
          </div>
          <div className="grid-items">
            <div className="all-text-grid-alignment">
              <div className="text-grid">
                <div className="text-grid-items">
                  <span>Adresse </span>
                </div>
                <div className="text-grid-items">
                  <p>
                    FE Finance GmbH Zollpfad 4<br />
                    28217 Bremen
                  </p>
                </div>
              </div>
              <div className="text-grid">
                <div className="text-grid-items">
                  <span>Telefon </span>
                </div>
                <div className="text-grid-items">
                  <a href="tel:+49 174 1772709">
                    <p>+49 174 1772709</p>
                  </a>
                </div>
              </div>
              <div className="text-grid">
                <div className="text-grid-items">
                  <span>E-Mail</span>
                </div>
                <div className="text-grid-items">
                  <a href="mailto:kommunikation@fe-finance.de?subject = Feedback&body = Message">
                    <p style={{ whiteSpace: "nowrap" }}>
                      kommunikation@fe-finance.de
                    </p>
                  </a>
                </div>
              </div>
              <div className="text-grid">
                <div className="text-grid-items">
                  <span>Erreichbarkeit</span>
                </div>
                <div className="text-grid-items">
                  <p>
                    Montag-Freitag <br />
                    09.00Uhr - 17.00Uhr <br />
                    <br />
                    Samstag & Sonntag <br />
                    nur nach Vereinbarung
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
