import React from "react";

export default function UnternehmensberatungSteper() {
  return (
    <div>
      <div className="all-steper-top-rigth-content-alignment">
        <div className="all-steper-grid-alignment">
          <div className="steper-grid">
            <div className="steper-grid-items">
              <a href="#Unternehmensberatung">
                <span>Unternehmens-beratung</span>
              </a>
            </div>
            <div className="steper-grid-items">
              <div className="steper-round-design">
                <div></div>
              </div>
            </div>
          </div>
          <div className="steper-grid">
            <div className="steper-grid-items">
              <a href="#Sicher-sein-mitNORM">
                <span className="steper-all-content-new-alignment">
                  Sicher sein mitNORM
                </span>
              </a>
            </div>
            <div className="steper-grid-items">
              <div className="steper-round-design">
                <div></div>
              </div>
            </div>
          </div>
          <div className="steper-grid">
            <div className="steper-grid-items">
              <a href="#Mitarbeiter">
                <span>Mitarbeiter finden, stärken und binden</span>
              </a>
            </div>
            <div className="steper-grid-items">
              <div className="steper-round-design">
                <div></div>
              </div>
            </div>
          </div>
          <div className="steper-grid">
            <div className="steper-grid-items">
              <span className="steper-all-content-new-alignment">
                Beratung vereinbaren
              </span>
            </div>
            <div className="steper-grid-items">
              <div className="steper-round-design">
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
