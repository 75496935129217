import React from "react";
import "./unsereNetzwerkpartner.scss";
import PartnerLogo from "../../../../assets/icons/partner-logo.png";
import PartnerLogo1 from "../../../../assets/icons/p2.png";
import PartnerLogo3 from "../../../../assets/icons/p3.png";
import PartnerLogo4 from "../../../../assets/icons/p4.png";
import PartnerLogo5 from "../../../../assets/icons/p5.png";
import PartnerLogo6 from "../../../../assets/icons/p6.png";
import PartnerLogo7 from "../../../../assets/icons/p7.png";
import PartnerLogo8 from "../../../../assets/icons/p8.png";
import DasAngebot2 from "../../../DasAngebot2/DasAngebot2";
export default function UnsereNetzwerkpartner() {
  return (
    <div>
      <div>
        <DasAngebot2 />
      </div>
      <div className="unsere-net-wekpartner-content-alignment">
        <div className="page-title">
          <h2>Unsere Netzwerkpartner</h2>
        </div>
        <div className="all-partner-all-logo-alignment">
          <div className="partner-logo-alignment">
            <div>
              <img src={PartnerLogo} alt="PartnerLogo" />
            </div>
            <div>
              <img src={PartnerLogo1} alt="PartnerLogo1" />
            </div>
            <div>
              <img src={PartnerLogo3} alt="PartnerLogo3" />
            </div>
            <div>
              <img src={PartnerLogo4} alt="PartnerLogo4" />
            </div>
          </div>
          <div className="partner-logo-alignment">
            <div>
              <img src={PartnerLogo5} alt="PartnerLogo5" />
            </div>
            <div>
              <img src={PartnerLogo6} alt="PartnerLogo6" />
            </div>
            <div>
              <img src={PartnerLogo7} alt="PartnerLogo7" />
            </div>
            <div>
              <img src={PartnerLogo8} alt="PartnerLogo8" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
