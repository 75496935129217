import React, { useState } from "react";
import "./MobileViewHeader.scss";
import WhiteLogo from "../../assets/icons/white-logo.svg";
import FeFinanceLogo from "../../assets/logo/fefinance1.svg";
import FeWerder from "../../assets/logo/FE-F.svg";
import MobileMenu from "../../assets/icons/mobile-menu.svg";
import { NavLink } from "react-router-dom";

export default function MobileViewHeader() {
  const [mobileHeaderOpen, setMobileHeaderOpen] = useState(false);

  return (
    <div>
      <div className="mobile-view-header-alignment">
        <div className="first-row-content-alignment">
          <div className="left-side-content">
          <NavLink to="/">
            <a>
            <div className="two-contnet-alignment">
              <img
              className="image-first"
              src={FeFinanceLogo} alt="FeFinanceLogo"
              />
              <img
              className="sec-first"
                src={FeWerder}
                alt=""
              />
              </div>
            </a>
            </NavLink>
          </div>
          <div className="right-side-content">
            <div
              className="mobile-menu"
              onClick={() => setMobileHeaderOpen(!mobileHeaderOpen)}
            >
              <img src={MobileMenu} alt="MobileMenu" />
            </div>
            <div className="white-button">
              <NavLink to="/steper-view">
                <button>JETZT BERATEN LASSEN</button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          mobileHeaderOpen
            ? "mobile-sidebar mobile-sidebar-show"
            : "mobile-sidebar mobile-sidebar-hidden"
        }
      >
        <div className="mobile-sidebar-header-alignment">
          <div>
            <img src={WhiteLogo} alt="WhiteLogo" />
          </div>
          <div onClick={() => setMobileHeaderOpen(false)}>
            <i class="fa-solid fa-xmark"></i>
          </div>
        </div>
        <div className="mobile-sidebar-body-content-alignment">
          <ul>
            <NavLink to="/financial-guidance">
              <li>FINANCIAL GUIDANCE</li>
            </NavLink>
            <NavLink to="/karriereinstitut">
              <li>KARRIEREINSTITUT</li>
            </NavLink>
            <NavLink to="/personalberatung">
              <li>PERSONALBERATUNG</li>
            </NavLink>
            <a href="http://fe-finance.solar-pvcheck.de/" target="_blank">
                          <li>
                          
  PV CHECK</li>
                        </a>
            <NavLink to="/unternehmensberatung">
              <li>UNTERNEHMENSBERATUNG</li>
            </NavLink>
            <NavLink to="/kontakt">
              <li>KONTAKT</li>
            </NavLink>
            <NavLink to="/karriere">
              <li>KARRIERE</li>
            </NavLink>
            <NavLink to="/uber-uns">
              <li>ÜBER FE FINANCE</li>
            </NavLink>
          </ul>
        </div>
      </div>
    </div>
  );
}
