import React, { useEffect, useState, useCallback } from "react";
import { toast, ToastContainer } from "react-toastify";
import { ApiGet, ApiPost, ApiPut } from "../../../../helpers/API/ApiData";
import "./forthSteper.scss";
import SteperRightIcon from "../../../../assets/icons/steper-right.svg";
import { NavLink, useHistory } from "react-router-dom";

export default function ForthSteper(props) {
  const {setp3,setp2,setp1,setTabview} = props;

  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [inputValue, setInputValue] = useState({});
  const [isAgree, setIsAgree] = useState(false);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const regexEmail =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const validateforUserData = () => {
    let isFormValid = true;
    let errors = {};
    if (!inputValue?.fname?.trim() || inputValue?.fname === "") {
      isFormValid = false;
      errors["fname"] = "*Bitte überprüfen Sie ihre Eingabe.";
    }
    if (!inputValue?.lname?.trim() || inputValue?.lname === "") {
      isFormValid = false;
      errors["lname"] = "*Bitte überprüfen Sie ihre Eingabe.";
    }
    if (
      !inputValue?.email?.trim() ||
      inputValue?.email === "" ||
      regexEmail?.test(inputValue?.email) === false
    ) {
      isFormValid = false;
      errors["email"] = "*Bitte überprüfen Sie ihre Eingabe.";
    }

    if (!inputValue?.contactNo || inputValue?.contactNo === "") {
      isFormValid = false;
      errors["contactNo"] = "*Bitte überprüfen Sie ihre Eingabe.";
    }
    if (!inputValue?.postCode || inputValue?.postCode === "") {
      isFormValid = false;
      errors["postCode"] = "*Bitte überprüfen Sie ihre Eingabe.";
    }
    if (isAgree === false) {
      isFormValid = false;
      errors["check"] = "*Bitte überprüfen Sie ihre Eingabe.";
    }

    setErrors(errors);
    return isFormValid;
  };

  const submitContactForm = () => {
    if (validateforUserData()) {
      let data = {
        firstName: inputValue?.fname,
        lastName: inputValue?.lname,
        email: inputValue?.email,
        contactNo: inputValue?.contactNo,
        postCode: inputValue?.postCode,
        option1:setp1?.[0],
        option2:setp2?.[0],
        option3:setp3?.[0]
      };
      ApiPost(`congrates/create`, data)
        .then((res) => {
          setInputValue({});
          toast.success("Vielen Dank, Ihre Daten wurden erfolgreich eingereicht.");
          history.push("/financial-guidance");
        })
        .catch((error) => {
          toast.error("Etwas ist schief gelaufen. Bitte versuche es erneut");
        });
    }
  };

  const bindInput = (value) => {
    var regex = new RegExp("^[^0-9]*$");
    var key = String.fromCharCode(
      !value.charCode ? value.which : value.charCode
    );
    if (regex.test(key)) {
      value.preventDefault();
      return false;
    }
  };

  return (
    <>
      <div className="first-steper-all-content-alignment">
        <div className="text-grid">
          <div className="text-grid-items">
            <div className="text-image-alignment">
              <span>4</span>
              <img src={SteperRightIcon} alt="SteperRightIcon" />
            </div>
          </div>
          <div className="text-grid-items">
            <div className="sub-text-style">
              <h2>Glückwunsch </h2>
              <p>Du hast es fast geschafft!</p>
            </div>
            <div className="black-text-style">
              <p>So können wir dich erreichen</p>
            </div>
            <div className="all-inpt-style-alignment">
              <input
                type="text"
                placeholder="Vorname"
                id="fname"
                name="fname"
                value={inputValue?.fname}
                onChange={(e) => {
                  handleOnChange(e);
                }}
              />
              <span style={{ color: "#d92c2c" }}>{errors?.fname}</span>

              <input
                type="text"
                placeholder="Nchname"
                id="lname"
                name="lname"
                value={inputValue?.lname}
                onChange={(e) => {
                  handleOnChange(e);
                }}
              />
              <span style={{ color: "#d92c2c" }}>{errors?.lname}</span>

              <input
                type="text"
                placeholder="Postleitzahl"
                onKeyPress={bindInput}
                id="postCode"
                name="postCode"
                value={inputValue?.postCode}
                onChange={(e) => {
                  handleOnChange(e);
                }}
              />
              <span style={{ color: "#d92c2c" }}>{errors?.postCode}</span>

              <input
                type="text"
                placeholder="Email"
                id="email"
                name="email"
                value={inputValue?.email}
                onChange={(e) => {
                  handleOnChange(e);
                }}
              />
              <span style={{ color: "#d92c2c" }}>{errors?.email}</span>

              <input
                type="text"
                placeholder="Telefon"
                onKeyPress={bindInput}
                id="contactNo"
                name="contactNo"
                value={inputValue?.contactNo}
                onChange={(e) => {
                  handleOnChange(e);
                }}
              />
              <span style={{ color: "#d92c2c" }}>{errors?.contactNo}</span>
            </div>
            <div className="last-button-style">
              <button onClick={() => submitContactForm()}>Absenden</button>
            </div>
            <div className="checkbox-content-alignment">
              <div>
                <input
                  type="checkbox"
                  checked={isAgree}
                  name="check"
                  id="new-check"
                  onChange={(e) => setIsAgree(e.target.checked)}
                />
                <label for="new-check"></label>
              </div>

              <div>
                <span>
                  Hiermit akzeptiere ich die Datenschutzbestimmungen der FE
                  Finance GmbH
                </span>
              </div>
            </div>
            <span style={{ color: "#d92c2c" }}>{errors?.check}</span>
          </div>
        </div>
        <div className="button-left-alignment">
          {/* <NavLink to="/"> */}
            <button onClick={() =>setTabview("three")}>zurück</button>
          {/* </NavLink> */}
        </div>
      </div>
    </>
  );
}
